import React, { FC } from "react";
import styled from "styled-components";
import { P, H3, Button, ButtonWrap } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import { IInfoCard } from "modules/Api";
import { Logo } from "modules/Header/components/Logo";

/**
 * Imageless card
 */
export const InfoCard: FC<IInfoCard> = ({
  id = "",
  title = "",
  d_description = "",
  link = "",
  btnText = "Read More",
  image,
  onClick,
}) => {
  console.log(!!image);
  return (
    <Margin>
      <Wrap>
        <ImageWrap
          style={{
            backgroundImage: `url(${image || ""})`,
          }}>
          {!Boolean(image) ? (
            <LogoWrap>
              <Logo style={{ fill: colors.grey3, width: "50%" }} />
            </LogoWrap>
          ) : null}
        </ImageWrap>
        <Content>
          <P variant={"lg"}>{title}</P>
          {d_description && <P>{d_description}</P>}
        </Content>
        {btnText && (
          <ButtonAbs>
            <ButtonWrap align="left" style={{ marginBottom: 0 }}>
              <Button href={link} target="_blank" onClick={() => onClick(id)} variant={"white"}>
                {btnText}
              </Button>
            </ButtonWrap>
          </ButtonAbs>
        )}
      </Wrap>
    </Margin>
  );
};

const Wrap = styled.div`
  margin-bottom: 30px;
  background-color: ${colors.grey1};
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  @media ${mediaQuery.md} {
    height: 100%;
    margin-bottom: 0;
  }
`;

const Margin = styled.div`
  @media ${mediaQuery.md} {
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;
  }
`;

const Content = styled.div`
  padding: 30px 25px 80px;
  position: relative;
`;

const ButtonAbs = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 0 25px;
`;

const ImageWrap = styled.div`
  background-color: ${colors.grey4};
  padding-top: 51%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const LogoWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
