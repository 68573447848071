import React, { useEffect, FC } from "react";
import { useCursorPosition } from "shared/hooks";
import styled from "styled-components";
import { PlayStatus } from "./PlayStatus";

export const VideoPlayerCursor = ({ isPlaying, onClick, videoDuration, videoCurrentTime, hideProgress }) => {
  const { x, y, hidden } = useCursorPosition();

  return (
    <Cursor style={{ top: y, left: x }} hidden={hidden} onClick={onClick} className="video-cursor">
      <PlayStatus
        hideProgress={hideProgress}
        currentTime={videoCurrentTime}
        duration={videoDuration}
        size={16}
        width={60}
        isPlaying={isPlaying}
        style={{ position: "relative", top: 2 }}
      />
    </Cursor>
  );
};

const Cursor = styled.div<{ hidden: boolean }>`
  position: fixed;
  z-index: 100;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 100;
  pointer-events: none;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  cursor: none;

  * {
    cursor: none;
  }
`;
