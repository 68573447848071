import React, { FC } from "react";
import { colors, fontFamily, IColors, mediaQuery } from "shared/styles";
import styled, { css } from "styled-components";

interface IBaseProps {
  align?: "left" | "center" | "right";
  color?: IColors;
  textTransform?: "none" | "uppercase" | "lowercase" | "capitalize";
  /**
   * Bottom margin
   */
  mb?: number;
  fontWeight?: "normal" | "bold" | "600" | "700";
}

const getColor = (color) => {
  return colors[color || "grey4"];
};

const bottomMargin = "10px";

export const getBaseTypoStyles = (props) => {
  return css<IBaseProps>`
    font-family: ${fontFamily};
    color: ${(props) => getColor(props?.color)};
    text-align: ${(props) => (props.align ? props.align : "left")};
    text-transform: ${(props) => (props.textTransform ? props.textTransform : "none")};
    line-height: 1em;
    margin: 0 0 ${bottomMargin};
    margin-bottom: ${(props) => (props.mb !== undefined ? props.mb + "px" : bottomMargin)};
  `;
};

// const Base = styled.p<IBaseProps>`
//   ${(props) => getBaseTypoStyles(props)}
// `;

// export const FontArial = styled(Base)`

// `;

// export const FontCourier = styled(Base)`
//   font-family: "Courier";
// `;

/**
 *
 * Headings Styles
 *
 */

type IHeaderProps = IBaseProps;

export const H1 = styled.h1<IHeaderProps>`
  ${(props) => getBaseTypoStyles(props)}
  font-size: 40px;
  line-height: 100%;
  margin-bottom: ${(props) => (props.mb !== undefined ? props.mb + "px" : "20px")};
  font-weight: 600;

  @media ${mediaQuery.md} {
    font-size: 62px;
  }
`;

export const H2 = styled.h2<IHeaderProps>`
  ${(props) => getBaseTypoStyles(props)}
  font-size: 40px;
  line-height: 100%;
  margin-bottom: ${(props) => (props.mb !== undefined ? props.mb + "px" : "20px")};
  font-weight: 600;

  @media ${mediaQuery.md} {
    font-size: 56px;
  }
`;

export const H3 = styled.h3<IHeaderProps>`
  ${(props) => getBaseTypoStyles(props)}
  font-size: 24px;
  line-height: 34px;
  margin-bottom: ${(props) => (props.mb !== undefined ? props.mb + "px" : "15px")};
  font-weight: 600;

  @media ${mediaQuery.md} {
    font-size: 36px;
    line-height: 42px;
  }
`;

export const H4 = styled.h4<IHeaderProps>`
  ${(props) => getBaseTypoStyles(props)}
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: ${(props) => (props.mb !== undefined ? props.mb + "px" : "15px")};

  @media ${mediaQuery.md} {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const H5 = styled.h4<IHeaderProps>`
  ${(props) => getBaseTypoStyles(props)}
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: ${(props) => (props.mb !== undefined ? props.mb + "px" : "10px")};

  @media ${mediaQuery.md} {
    font-size: 20px;
    line-height: 24px;
  }
`;

/**
 *
 * Paragraphs Styles
 *
 */

const getVariation = (props) => {
  const config = {
    sm: css<IParagraphProps>`
      font-size: 13px;
      line-height: 150%;
    `,
    normal: css<IParagraphProps>`
      font-size: 15px;
      line-height: 150%;
    `,
    lg: css<IParagraphProps>`
      font-size: 20px;
      line-height: 150%;
    `,
    menu: css<IParagraphProps>`
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
  };

  return config[props.variant || "normal"];
};

interface IParagraphProps extends IBaseProps {
  variant?: "normal" | "sm" | "lg" | "menu";
}

/**
 * Generic paragraph
 */

export const P = styled.p<IParagraphProps>`
  ${(props) => getBaseTypoStyles(props)}
  ${(props) => getVariation(props)}
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : null)}
`;

export const Label = styled.label`
  ${(props) => getBaseTypoStyles(props)};
  font-size: 13px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  display: block;
  color: ${({ color }) => color ?? colors.grey4};
  margin-bottom: 5px;
`;
