import { uiState } from "modules/UI";
import React from "react";
import { H1, InnerBodyMax, InnerPage, Loader, OverlayRoute, P } from "shared/components";
import { Routes } from "modules/Router/routes";
import { Col, Container, Row } from "react-grid-system";
import { mediaQuery } from "shared/styles";
import styled from "styled-components";
import { Ratings } from "modules/Dashboard";
import { Helmet } from "react-helmet";
import { endpoints, IInnerPageResponse } from "modules/Api";
import { useGet } from "shared/hooks/useApi";

export const Feedback = ({ match, history, slug }) => {
  const { data, loading } = useGet<IInnerPageResponse>(endpoints.innerPage, { data: { slug } });

  return (
    <OverlayRoute onBack={() => history.push(Routes.Dashboard)} match={match} type="inner-page">
      <Helmet>
        <title>Valor Connect - Feedback</title>
      </Helmet>
      <InnerPage className="inner-body">
        <InnerBodyMax>
          {loading ? (
            <LoaderWrap>
              <Loader size={60} />
            </LoaderWrap>
          ) : (
            <>
              <Row>
                <Col md={8} lg={6}>
                  <H1>{data?.title || ""}</H1>
                  {(data?.desc || "").split("\n").map((p, i) => (
                    <P variant="lg" key={i}>
                      {p}
                    </P>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Ratings />
                </Col>
              </Row>
            </>
          )}
        </InnerBodyMax>
      </InnerPage>
    </OverlayRoute>
  );
};

const LoaderWrap = styled.div`
  margin-top: 50px;
`;

const Content = styled.div`
  padding-top: 20px;

  @media ${mediaQuery.md} {
    padding-top: 50px;
  }
`;
