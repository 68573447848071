import React, { FC } from "react";
import { colors, fontFamily } from "shared/styles";
import styled from "styled-components";
import { H3 } from "../Typography";
import * as DropdownSelect from "react-dropdown-select";

export const Field = styled.div`
  margin-bottom: 35px;
`;

// interface IBase {
//   name: string;
//   type?: "text" | "checkout" | "number" | "email" | "hidden" | "radio";
//   disabled?: boolean;
//   onChange?: (e) => void;
// }
// export const Input: FC<IBase> = (props) => {
//   const renderInput = (type) => {
//     switch (type) {
//       case "text":
//       case "email":
//       case "number":
//         return <TextInput {...props} />;

//       default:
//         return null;
//     }
//   };

//   return renderInput(props?.type || "text");
// };

export const FieldLabel = styled(H3)`
  max-width: ;
`;

export const TextInput = styled.input`
  font-family: ${fontFamily};
  border: none;
  border-bottom: 1px solid ${colors.grey3};
  color: ${colors.grey3};
  padding: 5px 8px;
  font-size: 15px;
  line-height: 150%;
  transition: 0.3s;
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.primary};
  }

  &::placeholder {
    color: ${colors.grey2};
  }
`;

export const GreyInput = styled(TextInput)`
  background-color: ${colors.grey1};
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.grey1};

  &:focus {
    outline: none;
    border-color: ${colors.grey2};
  }
`;

export const DarkInput = styled(TextInput)`
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: ${colors.white};
  border-radius: 6px;
  background-color: transparent;

  &:focus {
    outline: none;
    border: 1px solid ${colors.white};
  }
`;

export const Select: FC<DropdownSelect.SelectProps<any>> = (props) => {
  return <StyledSelect {...props} />;
};

const StyledSelect = styled(DropdownSelect.default)`
  font-family: ${fontFamily};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 2px 5px 2px 8px !important;
  border-radius: 4px !important;
  background-color: ${colors.white};
  min-height: 25px !important;
  border: 1px solid ${colors.white};
  box-shadow: none;

  &:hover,
  &:focus {
    border-color: ${colors.white} !important;
    box-shadow: none !important;
  }

  .react-dropdown-select-input {
    font-size: 12px;
  }
`;
