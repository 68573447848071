import React from "react";
import { Button, MenuLink, Menu as NavMenu, MenuItem, Dropdown, DropdownMenuLink } from "shared/components";
import { PlayStatus } from "modules/Landing/components/PlayStatus";
import { Hidden, Visible } from "react-grid-system";
import { colors } from "shared/styles";
import { landingState } from "modules/Landing";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Volume, VolumeOff } from "shared/components/Icon";

/**
 * Logged out menu all sizes
 */
export const LoggedOutMenu = () => {
  const { videoMode, muted, isPlaying, videoCurrentTime, videoDuration, hasLoaded } = useRecoilValue(landingState);
  const setLandingState = useSetRecoilState(landingState);

  const SoundIcon = muted ? VolumeOff : Volume;

  const toggleMute = () => {
    setLandingState((state) => ({ ...state, muted: !state.muted }));
  };

  const openLogin = () => {
    setLandingState((state) => ({ ...state, loginOpen: true, isPlaying: false }));
  };

  const togglePlay = () => {
    setLandingState((state) => ({ ...state, isPlaying: !state.isPlaying }));
  };

  return (
    <>
      {videoMode === "active" && hasLoaded && (
        <>
          <Visible xs sm>
            <div onClick={togglePlay}>
              <PlayStatus
                hideProgress={false}
                currentTime={videoCurrentTime}
                duration={videoDuration}
                size={10}
                width={36}
                isPlaying={isPlaying}
                style={{ position: "relative", top: 2 }}
              />
            </div>
          </Visible>
          <MenuItem>
            <MenuLink onClick={toggleMute}>
              <Hidden xs>
                <span style={{ display: "inline-block", paddingRight: 5 }}>{muted ? "Sound Off" : "Sound On"}</span>
              </Hidden>

              <SoundIcon size={14} color={colors.white} />
            </MenuLink>
          </MenuItem>
        </>
      )}
      <MenuItem>
        <Button variant="inverse" size="md" onClick={openLogin}>
          Login
        </Button>
      </MenuItem>
    </>
  );
};
