import React from "react";
import { Icon } from "./Icon";

export const Add = (props) => <Icon {...props} name="add" />;
export const ArrowDown = (props) => <Icon {...props} name="arrow_down" />;
export const ArrowDownThin = (props) => <Icon {...props} name="arrow_down_thin" />;
export const ArrowForwardStem = (props) => <Icon {...props} name="arrow_forward_stem" />;
export const ArrowLeft = (props) => <Icon {...props} name="arrow_left" />;
export const ArrowLeftThin = (props) => <Icon {...props} name="arrow_left_thin" />;
export const ArrowRight = (props) => <Icon {...props} name="arrow_right" />;
export const ArrowRightThin = (props) => <Icon {...props} name="arrow_right_thin" />;
export const ArrowUp = (props) => <Icon {...props} name="arrow_up" />;
export const Close = (props) => <Icon {...props} name="close" />;
export const Error = (props) => <Icon {...props} name="error" />;
export const Info = (props) => <Icon {...props} name="info" />;
export const Pause = (props) => <Icon {...props} name="pause" />;
export const Play = (props) => <Icon {...props} name="play" />;
export const Remove = (props) => <Icon {...props} name="remove" />;
export const Success = (props) => <Icon {...props} name="success" />;
export const Volume = (props) => <Icon {...props} name="volume" />;
export const VolumeOff = (props) => <Icon {...props} name="volume_off" />;
export const Warning = (props) => <Icon {...props} name="warning" />;
