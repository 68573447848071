import React, { FC, memo } from "react";
import { Overlay } from "shared/components";
import styled from "styled-components";

// interface IBackgroundVideo extends HTMLVideoElement {
//   src: any;
//   poster: any;
//   renderOverlay?: () => React.ReactElement<any, any>;
// }

const defaultProps = {
  playsInline: true,
  muted: true,
  loop: true,
  autoPlay: true,
};

/**
 * Html 5 implementation
 */
export const BackgroundVideo: FC<any> = memo(({ src, poster = "", renderOverlay, videoRef, ...videoProps }) => {
  const vidProps = {
    ...defaultProps,
    ...(videoProps || {}),
  };

  return (
    <Wrap>
      {renderOverlay ? renderOverlay() : <Overlay opacity={0.65} />}
      <VideoComp {...vidProps} poster={poster} ref={videoRef}>
        <source src={src} type="video/mp4"></source>
      </VideoComp>
    </Wrap>
  );
});

const Wrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const VideoComp = styled.video<any>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
