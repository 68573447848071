import React, { useCallback, useEffect } from "react";
import { colors, headerHeight, mediaQuery } from "shared/styles";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { uiState } from "modules/UI";
import throttle from "lodash/throttle";

export const AdminPage = ({ children }) => {
  const setUiState = useSetRecoilState(uiState);
  const onScroll = useCallback(
    throttle((e) => {
      const pageScrolled = e?.target?.scrollTop || 0;
      setUiState((s) => ({ ...s, pageScrolled }));
    }, 100),
    [],
  );

  useEffect(() => {
    return () => {
      setUiState((s) => ({ ...s, pageScrolled: 0 }));
    };
  }, []);

  return (
    <Wrap className="admin-page" onScroll={onScroll}>
      {children}
    </Wrap>
  );
};

export const AdminBody = ({ children }) => {
  return <BodyWrap className="admin-body">{children}</BodyWrap>;
};

const BodyWrap = styled.div``;

const Wrap = styled.div`
  height: 100vh;
  padding-top: ${headerHeight.sm + 20}px;
  padding-bottom: 20px;
  overflow-y: auto;

  @media ${mediaQuery.md} {
    padding-top: ${headerHeight.lg + 20}px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li,
  a,
  td,
  label {
    color: ${colors.white};
  }
`;
