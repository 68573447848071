import { uiState } from "modules/UI";
import React from "react";
import { useRecoilState } from "recoil";
import { Modal, TextInput, Label, Field, getBaseTypoStyles, Button } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import styled from "styled-components";

export const Subscribe = () => {
  const [ui, setUiState] = useRecoilState(uiState);

  const close = () => {
    setUiState((s) => ({ ...s, subscribeModalOpen: false }));
  };

  return (
    <Modal isOpen={ui.subscribeModalOpen} close={close}>
      <Wrap className="subscribe">
        <form>
          <Field>
            <Label color="white">Subscribe</Label>
            <Input type="email" placeholder="Enter Email" autoFocus />
            <ButtonWrap>
              <Button variant="inverse" size="md">
                Subscribe
              </Button>
            </ButtonWrap>
          </Field>
        </form>
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const ButtonWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  @media ${mediaQuery.md} {
    margin-top: 15px;
  }
`;

const Input = styled<any>(TextInput)`
  ${(props) => getBaseTypoStyles(props)}
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background-color: transparent;
  color: ${colors.white};
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 0;
  font-weight: 600;

  @media ${mediaQuery.md} {
    font-size: 40px;
  }

  &:focus {
    border-color: ${colors.white};
  }

  &::placeholder {
    color: ${colors.white};
  }

  @media ${mediaQuery.md} {
    font-size: 62px;
  }
`;
