import React, { FC } from "react";
import styled from "styled-components";
import { colors, mediaQuery } from "shared/styles";
import { H4, P, ArrowForwardStem } from "shared/components";
import { Label } from "./Typography";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";

export interface ICard {
  id?: string;
  title: string;
  desc: string;
  image: string;
  url: string;
}
export const Card: FC<ICard> = (props) => {
  const { title, desc, image, url } = props;

  return (
    <WrapLink to={url}>
      <Wrap style={{ backgroundImage: `url(${image})` }} className="card">
        <Callout className="callout">
          <CalloutLabel>View</CalloutLabel>
          <ArrowForwardStem size={20} fill={colors.primary} />
        </Callout>
        <Content className="content">
          <H4 color={"black"} align="center">
            {title}
          </H4>
          <P color={"black"} variant={"sm"} align="center" mb={0} className="desc">
            <LinesEllipsis text={desc} maxLine={2} component="span" />
          </P>
        </Content>
      </Wrap>
    </WrapLink>
  );
};

const WrapLink = styled(Link)`
  display: block;
`;

const CalloutLabel = styled(Label)`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  margin-bottom: 0;
  display: inline-block;
`;

const Callout = styled.div`
  width: 90px;
  height: 90px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  opacity: 0;
`;

const Content = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 68%;
  border-radius: 0 0 10px 10px;
  transition: 0.3s;

  @media ${mediaQuery.md} {
    top: 70%;
  }
`;

const Wrap = styled.div`
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  position: relative;
  position: relative;
  overflow: hidden;
  padding-top: 140%;
  margin: 0 auto;
  background-color: ${colors.grey3};

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0;
    transition: 0.3s;
  }

  h4,
  p {
    transition: 0.3s;
    position: relative;
  }

  .desc {
    span {
      display: inline-block;
    }
  }

  &:hover {
    cursor: pointer;

    &:before {
      opacity: 1;
    }

    .callout {
      opacity: 1;
    }

    .content {
      background-color: transparent;
    }

    h4 {
      color: ${colors.white};
      transform: translateY(40px);
    }

    p {
      transform: translateY(20px);
      opacity: 0;
    }
  }
`;
