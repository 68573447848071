import { IQuestion, sendAnalytic } from "modules/Api";
import React, { FC } from "react";
import { Expander } from "shared/components";

interface IFaqs {
  questions: IQuestion[];
}
export const Faqs: FC<IFaqs> = ({ questions }) => {
  if (!questions?.length) return null;

  const onInteract = async (id) => {
    await sendAnalytic({ id, type: "faq", event: "engagement" });
  };

  return (
    <>
      {questions.map((q, i) => (
        <Expander
          key={i}
          id={q?.id}
          title={q?.question}
          desc={q?.answer}
          defaultOpen={i === 0}
          onInteract={onInteract}
        />
      ))}
    </>
  );
};
