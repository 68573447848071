import React, { FC, useState } from "react";
import styled from "styled-components";
import Vimeo, { VimeoProps } from "@u-wave/react-vimeo";
import { Play } from "shared/components/Icon";
import { colors } from "shared/styles";

// const config = {
//   background: {
//     url: "https://player.vimeo.com/video/76979871?controls=0",
//     width: 500,
//     height: 300,
//     autoplay: true,
//     muted: true,
//     controls: false,
//     background: true,
//   },
//   // TODO video player
// };

interface IVimeo extends VimeoProps {
  image?: string;
  active?: boolean;
  // isPlaying: boolean;
}
export const VimeoPlayer: FC<IVimeo> = (props) => {
  const { image = "", active = true, video } = props;
  const [bgImage, setBGImage] = useState(image);
  const [showPlayer, setShowPlayer] = useState(false);
  const [error, setError] = useState(false);

  const play = () => {
    setShowPlayer(true);
  };

  const onError = (e) => {
    setError(true);
  };

  // get thumbnail
  //
  // const player = useRef<any>(null);
  // const videoStarted = useRef<boolean>(false);

  // const setHandlers = () => {
  //   player?.current.on("play", function () {
  //     videoStarted.current = true;
  //   });
  // };

  // const togglePlay = (paused) => {
  //   if (player.current && videoStarted.current) {
  //     if (paused) {
  //       player.current.play();
  //     } else {
  //       player.current.pause();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   player.current = new window["Vimeo"].Player(`vimeo-${id}`, {
  //     ...config.background,
  //     url: `https://player.vimeo.com/video/${id}`,
  //   });
  // }, []);

  // useEffect(() => {
  //   if (player.current) {
  //     setHandlers();
  //   }
  // }, [player]);

  // useEffect(() => {
  //   togglePlay(isPlaying);
  // }, [isPlaying]);

  // useEffect(() => {
  //   togglePlay(isPlaying);
  // }, [isPlaying]);

  // return <VideoWrap id={`vimeo-${id}`}></VideoWrap>;
  // const background = props?.image ? props?.image : `https://i.vimeocdn.com/video/${props.video}`;

  const hideClass = showPlayer ? "hide" : "show";
  const bgClass = bgImage ? "has-bg" : "no-bg";
  return (
    <VideoWrap>
      <Vimeo {...props} paused={!showPlayer || !active} onError={onError} video={video} />
      <Preview style={{ backgroundImage: `url(${bgImage})` }} className={`${hideClass} ${bgClass}`}>
        <PlayWrap onClick={play} className="play-wrap">
          <Play size={30} color="white" />
        </PlayWrap>
      </Preview>
      {error && (
        <Error>
          <h4>
            There was an issue trying to play the video, refresh the page and try again. If the issue persists contact
            Valor support.
          </h4>
        </Error>
      )}
    </VideoWrap>
  );
};

const Error = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${colors.error};
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    color: ${colors.white};
    margin: 0 auto;
    max-width: 300px;
  }
`;

const PlayWrap = styled.div`
  width: 70px;
  height: 70px;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  svg {
    margin-left: 5px;
  }
`;

const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: 10;
  transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;

  &.hide {
    visibility: hidden;
    opacity: 0;
  }

  &.no-bg {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const VideoWrap = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  background-size: cover;
  background-position: center center;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
