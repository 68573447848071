import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminBody } from "modules/Admin/components/Shared";
import { Col, Container, Row } from "react-grid-system";
import { Dealers, Sidebar } from "modules/Admin/components";
import { Helmet } from "react-helmet";

export const Metrics: FC<RouteComponentProps> = ({ match, history }) => {
  return (
    <>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={7} lg={8} xl={9} push={{ md: 5, lg: 4, xl: 3 }}>
            <AdminBody>
              <Dealers />
            </AdminBody>
          </Col>
          <Col md={5} lg={4} xl={3} pull={{ md: 7, lg: 8, xl: 9 }}>
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </>
  );
};
