import React, { useState } from "react";
import { MenuItem, Dropdown, DropdownMenuLink } from "shared/components";
import { Routes } from "modules/Router/routes";
import { NavLink } from "react-router-dom";
import { useIsAdmin } from "modules/User/hooks";
import styled from "styled-components";

/**
 * Logged In Mobile Menu
 */
export const MobileMenu = ({ openSubscribe, logout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <Mobile>
      <Dropdown
        title={"Menu"}
        open={dropdownOpen}
        onToggle={() => setDropdownOpen((s) => !s)}
        onClose={() => setDropdownOpen(false)}>
        {isAdmin ? (
          <>
            <NavLink to={Routes.Metrics} className="router-link dashboard-link">
              <DropdownMenuLink>View Dashboard</DropdownMenuLink>
            </NavLink>
            <NavLink to={Routes.Dashboard} className="router-link site-link">
              <DropdownMenuLink>View Site</DropdownMenuLink>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink to={Routes.Feedback} className="router-link">
              <DropdownMenuLink>Feedback</DropdownMenuLink>
            </NavLink>
            {/* <DropdownMenuLink onClick={openSubscribe}>Subscribe</DropdownMenuLink> */}
          </>
        )}

        <DropdownMenuLink onClick={logout}>Logout</DropdownMenuLink>
      </Dropdown>
    </Mobile>
  );
};

const Mobile = styled(MenuItem)`
  .site-link {
    display: none;
  }

  .admin & {
    .dashboard-link {
      display: none;
    }

    .site-link {
      display: block;
    }
  }
`;
