import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "shared/styles/normalize.css";
import "swiper/swiper.scss";
import "shared/styles/global/styles.scss";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://234439badefa4e438d1ea462037a827d@o132769.ingest.sentry.io/5577823",
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
