import { post, get, put } from "modules/Api";
import { setStorage } from "modules/Storage";
import { IUser } from "modules/User";
import { IAPIResponse, endpoints } from "modules/Api/typings";

interface IAPIUserAuthResponse extends IUser {
  token: string;
}

/**
 * Login user endpoint
 */
export const login = (email, password): Promise<IAPIResponse<IAPIUserAuthResponse>> => {
  return post(endpoints.login, { email, password });
};

/**
 * Auto login for returning users based on session token
 */
export const autoLogin = (): Promise<IAPIResponse<IAPIUserAuthResponse>> => {
  return post(endpoints.auth);
};

/**
 * Logout user
 */
export const logout = async (): Promise<void> => {
  setStorage("session", {});
};

/**
 * Update user info. eg update their feedback rating
 */
export const updateUser = (data): Promise<IAPIResponse<IAPIUserAuthResponse>> => {
  // TODO proper endpoint
  return put(endpoints.user, { ...data });
};

type AnalyticsTypes = "video" | "resource" | "faq" | "poll" | "page";

interface IAnalyticResponse {
  success: string;
  message: string;
}

interface IOptions {
  type: AnalyticsTypes;
  event: string;
  // refers to the id of either the page or the individual item (video, faq etc...)
  id?: string;
}
export const sendAnalytic = (options: IOptions): Promise<IAPIResponse<IAnalyticResponse>> => {
  console.log("logging event", options);
  return post(endpoints.analytics, options);
};
