import React, { FC } from "react";
import { Col, Container, Row } from "react-grid-system";
import { P } from "shared/components";
import { Logo } from "./Logo";
import { Menu } from "./Menu";
import styled from "styled-components";
import { mediaQuery } from "shared/styles";
import { Routes } from "modules/Router/routes";
import { useHistory } from "react-router-dom";

interface IHeader {
  logoFill?: string;
  username: string;
  isLoggedIn: boolean;
}

export const HeaderComponent: FC<IHeader> = ({ logoFill, username, isLoggedIn }) => {
  const homeRoute = isLoggedIn ? Routes.Dashboard : Routes.Landing;
  const history = useHistory();
  const onHomeClick = () => {
    history.push(homeRoute);
  };
  const logoStyle = logoFill ? { fill: logoFill } : {};

  return (
    <Container style={{ width: "100%" }}>
      <Row justify="center" align="center" gutterWidth={10}>
        <Col xs={isLoggedIn ? 6 : 4} md={4} style={{ position: "relative" }}>
          <Logo style={logoStyle} onClick={onHomeClick} className="logo" />
          <MobileBump className="dashboard-label">
            <P variant="menu" color="white" mb={0}>
              DASHBOARD
            </P>
          </MobileBump>
        </Col>
        <Col
          xs={isLoggedIn ? 6 : 8}
          md={8}
          style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <Menu isLoggedIn={isLoggedIn} username={username} />
        </Col>
      </Row>
    </Container>
  );
};

const MobileBump = styled.div`
  display: none;
  position: absolute;
  left: 80px;
  top: 22px;
  @media ${mediaQuery.md} {
    top: 29px;
    left: 105px;
  }

  .admin & {
    display: block;
  }
`;
