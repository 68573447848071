import React, { FC } from "react";
import { iconMap } from "./icon-map";
import { IconNames } from "./typings";

interface IIconProps {
  name: IconNames;
  size?: number;
  color?: string;
  style?: any;
}

export const Icon: FC<IIconProps> = ({ name, size = 18, color = "#000000", style = {} }) => {
  const Icon = iconMap[name] || null;

  if (!Icon) return null;

  const iconStyles = {
    width: size,
    height: size,
    ...style,
  };

  return <Icon fill={color} style={iconStyles} />;
};
