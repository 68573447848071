/**
 * Careful renaming routes, we wrap the app with css classes based on theses
 * currently for certain style changes to the header mostly, so changing them
 * might break some styling
 */
export enum Routes {
  // guest routes
  Landing = "/",
  // Login = "/login",

  // authed routes - all prefaced with `dashboard`
  Dashboard = "/dashboard",
  Products = "/dashboard/products",
  Upgrades = "/dashboard/upgrades",
  Training = "/dashboard/training",
  ThankYou = "/dashboard/thank-you",
  Feedback = "/dashboard/feedback",
  StyleGuide = "/dashboard/style-guide",

  // admin routes - all prefacted with `admin`
  Admin = "/admin",
  Metrics = "/admin/metrics",
}

export const routeConfig = {
  [Routes.Landing]: { wrapperClass: "" },
  [Routes.Dashboard]: { wrapperClass: "" },
  [Routes.Products]: { wrapperClass: "overlay-route product resource-route" },
  [Routes.Upgrades]: { wrapperClass: "overlay-route upgrade resource-route" },
  [Routes.Training]: { wrapperClass: "overlay-route training resource-route" },
  [Routes.ThankYou]: { wrapperClass: "overlay-route inner-page" },
  [Routes.Feedback]: { wrapperClass: "overlay-route inner-page" },
  [Routes.Admin]: { wrapperClass: "" },
  [Routes.Metrics]: { wrapperClass: "" },
};
