import { IUser, IRole } from "modules/User";
import { atom, selector } from "recoil";

export interface ISession {
  token: string | null;
  user: IUser;
  resolved: boolean;
}

const initialSessionState: ISession = {
  token: null,
  user: {
    id: null,
    username: "",
    name: "",
    location: "CAN",
    role: IRole.Guest,
    feedback: null,
    dealer: [],
  },
  resolved: false,
};

export const sessionState = atom<ISession>({
  key: "session",
  default: { ...initialSessionState },
});

export const isLoggedInSelector = selector({
  key: "isLoggedIn",
  get: ({ get }) => {
    const session = get(sessionState);
    return Boolean(session?.token && session?.user?.id);
  },
});

export const isAdminSelector = selector({
  key: "isAdmin",
  get: ({ get }) => {
    const session = get(sessionState);
    return Boolean(session?.token && session?.user?.id && session?.user?.role === IRole.Admin);
  },
});
