import React, { FC, useEffect, useRef, useState } from "react";
import { containerWidths, mediaQuery } from "shared/styles";
import styled from "styled-components";
import * as typeformEmbed from "@typeform/embed";
import { useRecoilValue } from "recoil";
import { sessionState } from "modules/Auth";
import { H3 } from "shared/components";
import { Col, Container, Row } from "react-grid-system";
import { sendAnalytic } from "modules/Api";

interface IOption {
  id: string;
}
interface IPollQuestion {
  type: "text" | "radio";
  label: string;
  options?: IOption[];
}

export const Poll: FC<{ url: string; id: string }> = ({ url, id }) => {
  const embedWrap = useRef(null);
  const { user } = useRecoilValue(sessionState);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const hiddenFields = new URLSearchParams({
    // hides the welcome screen, probably never want that
    "typeform-welcome": "0",
    userId: encodeURIComponent(user?.id || ""),
  });

  const fullUrl = `${url}?${hiddenFields.toString()}`;

  const onSubmit = async (e) => {
    setHasSubmitted(true);
    await sendAnalytic({ id, type: "poll", event: "engagement" });
  };

  useEffect(() => {
    if (embedWrap.current) {
      typeformEmbed.makeWidget(embedWrap.current, fullUrl, {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
        onSubmit,
      });
    }
  }, [embedWrap]);

  if (!url) return null;

  return (
    <Wrap>
      {hasSubmitted ? (
        <Container>
          <Row>
            <Col>
              <H3>Thanks for filling out the survey!</H3>
            </Col>
          </Row>
        </Container>
      ) : (
        <div style={{ width: "100%", height: "100%" }} ref={embedWrap}></div>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  height: 400px;
  max-width: ${containerWidths.innerMaxWidth}px;
  position: relative;

  @media ${mediaQuery.md} {
    height: 550px;
  }
  @media ${mediaQuery.lg} {
    height: 600px;
  }
  @media ${mediaQuery.xl} {
    height: 625px;
  }
`;
