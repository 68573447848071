import React, { useEffect, useState } from "react";
import { H3, P, Intro, Overlay, Loader } from "shared/components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Login } from "modules/Auth";
import { VideoPlayerCursor, landingState } from "modules/Landing";
import styled from "styled-components";
import { Hidden, Visible } from "react-grid-system";
import { PlayBtn } from "modules/Landing/components/Play";
import { mediaQuery } from "shared/styles";
import { deviceType } from "detect-it";
import { FullPageWrap } from "shared/components";
import { useGet, useQueryParam } from "shared/hooks";
import { Video, VimeoBRoll } from "modules/Landing/components";
import { endpoints, ILandingResponse } from "modules/Api";

export const Landing = () => {
  const [showCursor, setShowCursor] = useState(true);
  // when user clicks to play video, switch from broll in background to main video
  const [activated, setActivated] = useState(false);
  const { loginOpen, isPlaying, videoMode, videoCurrentTime, videoDuration } = useRecoilValue(landingState);
  const setLandingState = useSetRecoilState(landingState);
  const loginParam = useQueryParam("login");
  const { data, loading } = useGet<ILandingResponse>(endpoints.landing);

  useEffect(() => {
    if (loginParam) {
      setLandingState((state) => ({ ...state, loginOpen: true, isPlaying: false }));
    }
  }, [loginParam, setLandingState]);

  const close = () => {
    setLandingState((state) => ({ ...state, loginOpen: false, isPlaying: true, videoMode: "active" }));
  };

  const togglePlay = () => {
    setLandingState((state) => ({ ...state, isPlaying: !state.isPlaying, videoMode: "active" }));
  };

  const activateVideo = () => {
    setActivated(true);
    setLandingState((state) => ({ ...state, muted: false }));
  };

  const cursorClick = (activated) => {
    if (!activated) {
      activateVideo();
    } else {
      togglePlay();
    }
  };

  const onMouseEnter = () => {
    setShowCursor(true);
  };

  const onMouseLeave = () => {
    setShowCursor(false);
  };

  if (loading) {
    return (
      <Loading>
        <Loader size={60} />
      </Loading>
    );
  }

  return (
    <>
      {activated ? <Video /> : <VimeoBRoll videoId={data?.bRoll} renderOverlay={() => <LandingGradient />} />}

      <PageContent onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => cursorClick(activated)}>
        {!activated && !loading && (
          <Intro>
            <div onClick={activateVideo}>
              <P variant="menu" color="white">
                {data?.title || ""}
                <Visible xs sm>
                  <PlayBtn size={10} width={24} isPlaying={false} style={{ top: 7, left: 5 }} />
                </Visible>
              </P>
              <H3 color="white">{data?.desc || ""}</H3>
            </div>
          </Intro>
        )}
      </PageContent>
      {!loginOpen && videoMode !== "theater" && deviceType === "mouseOnly" && showCursor && (
        <Hidden xs sm>
          <VideoPlayerCursor
            hideProgress={!activated}
            isPlaying={!activated ? false : isPlaying}
            onClick={() => cursorClick(!activated)}
            videoCurrentTime={videoCurrentTime}
            videoDuration={videoDuration}
          />
        </Hidden>
      )}
      {/* <p style={{ color: "#fff", position: "absolute", top: 200, left: 0, zIndex: 200 }}>
        {showCursor ? "Show" : "hide"}
      </p> */}
      <Login open={loginOpen} close={close} />
    </>
  );
};

const PageContent = styled(FullPageWrap)`
  @media ${mediaQuery.md} {
    cursor: ${deviceType === "mouseOnly" ? "none" : "default"};
  }
`;

// Landing States
// 1. Welcome message and BRoll playing (slight gradient)
// 2. User clicks play we play main video with audio
// 3. Mouse becomes pause/play toggle and we add mute option in header
// 4. At anytime user can click login which will pause video and show user login
// 5. If we hit end of video we open login for user
const LandingGradient = styled(Overlay)`
  background: radial-gradient(23.09% 41.05% at 0% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(210.94deg, rgba(0, 0, 0, 0.6) 4.2%, rgba(0, 0, 0, 0) 41.43%);
`;

const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
