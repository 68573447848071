import React, { FC } from "react";
import styled from "styled-components";
import { colors, mediaQuery } from "shared/styles";

export const Overlay = styled.div<{ opacity?: number; zIndex?: number }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${({ zIndex }) => zIndex || "1"};
  background-color: rgba(0, 0, 0, ${({ opacity }) => opacity || "0.3"});
  transition: 0.2s;
`;
