import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ToastType, toastInitialState, toastState } from "./state";

interface IOptions {
  type?: ToastType;
  requiresDismiss?: boolean;
}

export const useToast = (options?: IOptions) => {
  const setToast = useSetRecoilState(toastState);

  const finalOptions = useMemo(
    () => ({
      ...toastInitialState,
      ...(options || {}),
    }),
    [],
  );

  const openToast = useCallback(
    (message) => {
      setToast((t) => ({ ...finalOptions, isOpen: true, message }));
    },
    [setToast, finalOptions],
  );

  return openToast;
};
