import React from "react";
import { InnerPage } from "shared/components";
import { Col, Container, Row } from "react-grid-system";
import { InnerBodyMax } from "./Layout";

export const NotFound = ({ children }) => {
  return (
    <InnerPage className="inner-body not-found">
      <InnerBodyMax>
        <Row>
          <Col md={8} lg={6}>
            {children}
          </Col>
        </Row>
      </InnerBodyMax>
    </InnerPage>
  );
};
