import React, { FC } from "react";
import { Menu as NavMenu } from "shared/components";
import { mediaQuery } from "shared/styles";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { uiState } from "modules/UI";
import { LoggedOutMenu } from "./Menu/LoggedOut";
import { LoggedInMenu } from "./Menu/LoggedIn";

export const Menu: FC<{
  isLoggedIn: boolean;
  username?: string;
}> = ({ isLoggedIn, username = "" }) => {
  const setUiState = useSetRecoilState(uiState);

  const openSubscribe = () => {
    setUiState((s) => ({ ...s, subscribeModalOpen: true }));
  };

  return (
    <Wrap className="menu-wrap">
      <NavMenu className="menu">
        {isLoggedIn ? <LoggedInMenu openSubscribe={openSubscribe} username={username} /> : <LoggedOutMenu />}
      </NavMenu>
    </Wrap>
  );
};

const Wrap = styled.div``;
