import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "react-grid-system";
import { H5, H3, P, Select, AdminOverviewLoader, AdminPerformingLoader } from "shared/components";
import { endpoints, IMetric, post } from "modules/Api";
import { atomFamily } from "recoil";

const options = [
  { value: "week", label: "This Week" },
  { value: "month", label: "This Month" },
  { value: "year", label: "This Year" },
  { value: "alltime", label: "Alltime" },
];

const useEngagement = (endpoint, time: "week" | "month" | "year" | "alltime", type: "overview" | "performing") => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await post(endpoint, {
        time,
      });

      setLoading(false);
      setData(data?.[type] || []);
    };

    if (time) {
      setLoading(true);
      fetch();
    }
  }, [endpoint, time]);

  return {
    data,
    loading,
  };
};

export const Sidebar = () => {
  const [overviewOptions, setOverviewOptions] = useState([{ value: "alltime", label: "Alltime" }]);
  const [performingOptions, setPerformingOptions] = useState([{ value: "alltime", label: "Alltime" }]);

  const { data: overviewData, loading: loadingOverview }: { data: IMetric[]; loading: boolean } = useEngagement(
    endpoints.engagementOverview,
    (overviewOptions?.[0]?.value as any) || "alltime",
    "overview",
  );
  const { data: performingData, loading: performingLoading }: { data: any; loading: boolean } = useEngagement(
    endpoints.engagementPerforming,
    (performingOptions?.[0]?.value as any) || "alltime",
    "performing",
  );

  const onOverviewChange = (vals) => {
    setOverviewOptions(vals);
  };

  const onPerformingChange = (vals) => {
    setPerformingOptions(vals);
  };

  return (
    <SidebarWrap>
      <H3 mb={40}>Engagement</H3>
      <SidebarSection>
        <SelectHeader>
          <Row justify="between" align="center">
            <Col>
              <H5 mb={0}>Overview</H5>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Select
                disabled={loadingOverview}
                searchable={false}
                options={options}
                onChange={onOverviewChange}
                values={overviewOptions}
              />
            </Col>
          </Row>
        </SelectHeader>

        <AdminOverviewLoader ready={!loadingOverview}>
          {overviewData && overviewData?.length > 0 ? (
            <>
              {overviewData.map((r, i) => (
                <Row justify="between" align="center" key={i}>
                  <Col>
                    <P mb={5}>{r?.title || ""}</P>
                  </Col>
                  <Col>
                    <P align="right">{r?.count || 0}</P>
                  </Col>
                </Row>
              ))}
            </>
          ) : null}
        </AdminOverviewLoader>
      </SidebarSection>
      <SidebarSection>
        <SelectHeader>
          <Row justify="between" align="center">
            <Col>
              <H5 mb={0}>Top Performing</H5>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Select
                disabled={performingLoading}
                searchable={false}
                options={options}
                onChange={onPerformingChange}
                values={performingOptions}
              />
            </Col>
          </Row>
        </SelectHeader>
        <AdminPerformingLoader ready={!performingLoading}>
          {performingData && performingData?.length > 0 ? (
            <>
              {(performingData || [])
                .sort((a, b) => {
                  const aTotal = a.views + a.engagements;
                  const bTotal = b.views + b.engagements;
                  return bTotal - aTotal;
                })
                .slice(0, 5)
                .map((r, i) => (
                  <Video key={r?.data?.id || i}>
                    <Row align="center" gutterWidth={15}>
                      <Col xs="content">
                        <Image src={r?.data?.image} alt={r?.data?.title} />
                      </Col>
                      <Col>
                        <VideoInfo>
                          <VideoTitle mb={15}>{r?.data?.title}</VideoTitle>
                          <P variant="sm" mb={5}>
                            {r?.views || 0} Views
                          </P>
                          <P variant="sm" mb={0}>
                            {r?.engagements || 0} Engagements
                          </P>
                        </VideoInfo>
                      </Col>
                    </Row>
                  </Video>
                ))}
            </>
          ) : null}
        </AdminPerformingLoader>
      </SidebarSection>
    </SidebarWrap>
  );
};

const SidebarWrap = styled.div`
  padding-bottom: 20px;
`;

const Video = styled.div`
  margin-bottom: 20px;
`;

const VideoTitle = styled(P)`
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
`;

const VideoInfo = styled.div``;

const Image = styled.img`
  display: inline-block;
  width: 85px;
  height: 85px;
`;

const SelectHeader = styled.div`
  padding-bottom: 20px;
`;

const SidebarSection = styled.div`
  margin-bottom: 35px;
`;
