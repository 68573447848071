import * as api from "modules/Api";
import { useEffect, useState } from "react";
import { useToast } from "shared/components/Toast/hook";

interface IAPIData<T> {
  data: T | null;
  loading: boolean;
}

type MethodType = "get" | "post" | "put";

interface IUseApiConfig {
  data?: any;
  config?: any;
}

const apiRequest = (httpMethod: MethodType) => <T>(
  endpoint,
  options?: IUseApiConfig,
  cb?: (error) => void,
): IAPIData<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const toast = useToast({ type: "error", requiresDismiss: true });

  useEffect(() => {
    const apiCall = async () => {
      const method = httpMethod || "get";
      try {
        const { data } = await api[method](endpoint, options?.data || {}, options?.config || {});
        setData(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        const error = e?.message || e;
        setError(error);
        if (cb) {
          cb(e);
        } else {
          toast(error);
        }
      }
    };

    apiCall();
  }, [endpoint, httpMethod]);

  return {
    data,
    loading,
  };
};

export const useGet = apiRequest("get");

// export const usePost = apiRequest("post");

// export const usePut = <T>(endpoint, cb?: (error) => string): IAPIData<T> => {
//   return useApi(endpoint, "put", cb);
// };
