import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "shared/styles";
import "./index.css";
import { Label } from "../Typography";

export const Loader = ({ size = 40, color = colors.primary, style = {}, text = "", textStyles = {} }) => {
  return (
    <Wrap>
      <div className="spinner" style={{ width: size, height: size, margin: "0 auto" }}>
        <div className="double-bounce1" style={{ backgroundColor: color }}></div>
        <div className="double-bounce2" style={{ backgroundColor: color }}></div>
      </div>
      {text && (
        <Label color="white" style={{ ...textStyles, marginTop: 10 }} className="loading-text">
          {text}
        </Label>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  text-align: center;
`;

export const PageLoader = ({ inline = false }) => {
  const Wrap = inline ? LoaderInline : LoaderAbs;
  return (
    <Wrap>
      <Loader size={60} text={"Loading..."} />
    </Wrap>
  );
};

const LoaderAbs = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;

const LoaderInline = styled.div`
  text-align: center;
  margin: 20px 0;
`;
