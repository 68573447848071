import React, { useState } from "react";
import { MenuItem, Dropdown, DropdownMenuLink } from "shared/components";
import { Routes } from "modules/Router/routes";
import { Link } from "react-router-dom";
import { useIsMobile } from "shared/hooks";
import { MobileMenu } from "./Mobile";
import { DesktopMenu } from "./Desktop";
import { useToast } from "shared/components/Toast/hook";
import * as api from "modules/Api/service";
import { useScreenClass } from "react-grid-system";

/**
 * Logged in menu wrapper
 */
export const LoggedInMenu = (props) => {
  const screenClass = useScreenClass();

  const isMobile = ["xs", "sm", "md", "lg"].includes(screenClass);

  const toast = useToast({ type: "error", requiresDismiss: true });

  const logout = async () => {
    try {
      const { host, protocol } = window.location;
      await api.logout();
      window.location.replace(`${protocol}//${host}`);
    } catch (e) {
      console.log(e);
      toast("There's was a problem logging you out, please try again.");
    }
  };

  return isMobile ? <MobileMenu {...props} logout={logout} /> : <DesktopMenu {...props} logout={logout} />;
};
