import { sendAnalytic } from "modules/Api";
import React, { useEffect } from "react";
import { visitLexicalEnvironment } from "typescript";

export const OverviewAnalytic = ({ children, type, id, event }) => {
  useEffect(() => {
    const recordEvent = async () => {
      if (!id || !event || !type) return;

      try {
        await sendAnalytic({ id, type, event });
      } catch (e) {}
    };

    recordEvent();
  }, []);
  return children;
};
