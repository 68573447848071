/**
 * App Global Colors
 */
export const colors = {
  primary: "#BC2020",
  primaryDark: "#941717",
  primaryLight: "#DD2020",
  grey1: "#F1F1F1",
  grey2: "#858585",
  grey3: "#2C2C2C",
  grey4: "#101010",
  success: "#8DB979",
  warn: "#B6A724",
  /**
   * Primary Dark
   */
  error: "#941717",
  white: "#ffffff",
  black: "#000000",
  /**
   * White
   */
  text: "#ffffff",
  /**
   * Grey 1
   */
  textDark: "#F1F1F1",
  /**
   * White
   */
  textLight: "#ffffff",
  /**
   * Grey 4
   */
  textInverse: "#101010",
  /**
   * Black
   */
  textDarkInverse: "#000000",
  /**
   * Grey 3
   */
  textLightInverse: "#2C2C2C",
};

export type IColors =
  | "primary"
  | "primaryDark"
  | "primaryLight"
  | "grey1"
  | "grey2"
  | "grey3"
  | "grey4"
  | "success"
  | "warn"
  | "error"
  | "white"
  | "black"
  | "text"
  | "textDark"
  | "textLight"
  | "textInverse"
  | "textDarkInverse"
  | "textLightInverse";

/**
 * Media query breakpoints
 */
export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 1800,
};

/**
 * Container widths per breakpoints
 */
export const containerWidths = {
  sm: 540,
  md: 740,
  lg: 960,
  xl: 1140,
  xxl: 1540,
  xxxl: 1740,
  // max width for inner page content
  innerMaxWidth: 860,
};

export const mediaQuery = {
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
  smMax: `(max-width: ${breakpoints.sm - 1}px)`,
  mdMax: `(max-width: ${breakpoints.md - 1}px)`,
  lgMax: `(max-width: ${breakpoints.lg - 1}px)`,
  xlMax: `(max-width: ${breakpoints.xl - 1}px)`,
  xxlMax: `(max-width: ${breakpoints.xxl - 1}px)`,
};

export const fontFamily = "proxima-nova, sans-serif";

export const headerHeight = {
  sm: 50,
  // mediaQuery.md +
  lg: 105,
};

/**
 * Global elements z indexes
 */
export const zIndex = {
  toast: 2000,
  modal: 1500,
  header: 1000,
  overlayPage: 500,
  overlayPageBackBtn: 1100,
  // overlay under login drawer
  loginOverlay: 1100,
  loginDrawer: 1200,
};
