import { atom } from "recoil";

interface IDashboardState {
  /**
   * User is looking at the dashboard landing screen (autoplay tabs)
   */
  dashboardLandingFocused: boolean;

  /**
   * User has interacted with feature tabs in some way
   * - Click to another tab
   * - Scrolled slider
   */
  interactedWithTabs: boolean;
  tooltips: {
    /**
     * Remove the scroll tooltip on sliders once they've
     * scrolled slider
     */
    sliderScroll: boolean;
  };
}

export const dashboardInitialState: IDashboardState = {
  tooltips: {
    sliderScroll: false,
  },
  dashboardLandingFocused: true,
  interactedWithTabs: false,
};

export const dashboardState = atom<IDashboardState>({
  key: "dashboard",
  default: {
    ...dashboardInitialState,
  },
});
