import React, { FC, useState } from "react";
import styled from "styled-components";
import { H3, P } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import { Add, Remove } from "./Icon";

interface IExpander {
  title: string;
  desc: string;
  defaultOpen?: boolean;
  id?: string;
  onInteract?: (id) => void;
}

export const Expander: FC<IExpander> = ({ id = "", title = "", desc = "", defaultOpen = false, onInteract }) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const onClick = () => {
    setOpen((s) => !s);
    onInteract && onInteract(id);
  };

  const Icon = open ? Remove : Add;

  return (
    <Wrap className={open ? "expander open" : "expander"}>
      <QWrap onClick={onClick}>
        <div className="content-wrap">
          <H3 mb={0}>{title}</H3>
          <IconWrap>
            <Icon size={20} color={colors.black} />
          </IconWrap>
        </div>
      </QWrap>
      <AWrap className="answer-wrap">
        <div className="content-wrap">
          <P variant="lg" mb={0}>
            {desc}
          </P>
        </div>
      </AWrap>
    </Wrap>
  );
};

const QWrap = styled.div`
  cursor: pointer;
  margin-bottom: 15px;
  padding-right: 50px;
  position: relative;

  h3 {
    transition: 0.3s;
  }

  svg {
    transition: 0.3s;
  }

  &:hover {
    h3 {
      color: ${colors.primary};
    }

    svg {
      fill: ${colors.primary};
    }
  }
`;

const AWrap = styled.div``;

const IconWrap = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Wrap = styled.div`
  position: relative;
  margin-bottom: 30px;

  .content-wrap {
    max-width: 1200px;
  }

  @media ${mediaQuery.md} {
    margin-bottom: 50px;
  }

  .answer-wrap {
    display: none;
  }

  &.open {
    .answer-wrap {
      display: block;
    }
  }
`;
