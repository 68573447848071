import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { colors, fontFamily } from "shared/styles";
import { lightenDarkenColor } from "../utils";

type Type = "default" | "warning" | "success" | "error";
interface IFlashProps {
  type?: Type;
  withContainer?: boolean;
}

export const FlashMessage: FC<IFlashProps> = ({ children, type = "default", withContainer = false }) => {
  return withContainer ? (
    <Container>
      <Row align="center">
        <Col>
          <ContentWrap type={type}>{children}</ContentWrap>
        </Col>
      </Row>
    </Container>
  ) : (
    <ContentWrap type={type}>{children}</ContentWrap>
  );
};

const getType = (props) => {
  const types = {
    warning: css<IFlashProps>`
      background-color: ${colors.warn};
      border-color: ${lightenDarkenColor(colors.warn, -20)};
    `,
    success: css<IFlashProps>`
      background-color: ${colors.success};
      color: ${colors.white};
      border-color: ${lightenDarkenColor(colors.success, -20)};
    `,
    error: css<IFlashProps>`
      background-color: ${colors.error};
      color: ${colors.white};
      border-color: ${lightenDarkenColor(colors.error, -20)};
    `,
  };

  return types[props?.type || "default"];
};

const ContentWrap = styled.div<{ type: Type }>`
  background-color: ${colors.grey1};
  border: 1px solid ${lightenDarkenColor(colors.grey1, -20)};
  color: ${colors.grey4};
  padding: 20px;
  font-size: 13px;
  line-height: 120%;
  font-weight: 600;
  font-family: ${fontFamily};
  border-radius: 30px;
  ${(props) => getType(props)};
`;
