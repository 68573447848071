import React, { FC, useState } from "react";
import styled from "styled-components";
import { colors, mediaQuery, zIndex } from "shared/styles";
import { sessionState } from "modules/Auth";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { H3, P, Button, Field, TextInput, ButtonWrap, Label, Loader, FlashMessage } from "shared/components";
import { login } from "modules/Api/service";
import { useHistory } from "react-router-dom";
import { Routes } from "modules/Router/routes";
import { landingState } from "modules/Landing";
import { setStorage } from "modules/Storage";

export const Login: FC<{ open: boolean; close: () => void }> = ({ open, close }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const setSessionState = useSetRecoilState(sessionState);
  const resetLandingState = useResetRecoilState(landingState);

  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError("");
    const formData = new FormData(event.target);
    const email = formData.get("username");
    const password = formData.get("password");

    if (!email || !password) {
      return setError("Make you you've entered your username and password");
    }

    try {
      setSubmitting(true);

      const { data } = await login(email, password);

      if (!data?.token || !data?.id) {
        setError("There was an error login, please try again");
        setSubmitting(false);
        return;
      }

      const { token, ...user } = data;

      setSessionState((state) => ({ ...state, token, isLoggedIn: true, user }));
      resetLandingState();
      setStorage("session", { token: token, user });
      setSubmitting(false);
      history.push(Routes.Dashboard);
    } catch (e) {
      setError("Unable to login, please verify you entered the correct credentials");
      setSubmitting(false);
    }
  };

  return (
    <>
      <Wrap open={open}>
        {open && (
          <Close>
            <Button variant="inverse" size="md" onClick={close}>
              Close
            </Button>
          </Close>
        )}

        <ContentWrap open={open}>
          <div>
            <H3 color={"textInverse"} align={"center"} mb={40}>
              Dealer Login
            </H3>

            <form onSubmit={handleSubmit}>
              <Field>
                <Label>Username</Label>
                <TextInput type="text" placeholder="Enter your username" required name="username" />
              </Field>
              <Field>
                <Label>Password</Label>
                <TextInput type="password" placeholder="Enter your password" required name="password" />
              </Field>
              <ButtonWrap>
                <Button variant="secondary" type="submit" isLoading={submitting}>
                  {"Login"}
                </Button>
              </ButtonWrap>
              {error && <FlashMessage type="error">{error}</FlashMessage>}
            </form>
          </div>
          <Register>
            <P variant="sm" color={"grey2"} align={"center"}>
              Don’t have an account? <a href="https://www.valorfireplaces.com/request/">Become a Dealer</a>
            </P>
          </Register>
        </ContentWrap>
      </Wrap>
      <Overlay open={open} onClick={close} />
    </>
  );
};

const Close = styled.div`
  position: absolute;
  right: 100%;
  top: 42px;
  margin-right: 7px;

  @media ${mediaQuery.sm} {
    margin-right: 15px;
  }
`;

const Register = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ContentWrap = styled.div<{ open: boolean }>`
  height: 100%;
  position: relative;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.3s linear 0.1s;
  transform: translateX(${({ open }) => (open ? 0 : "20px")});
`;

const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${zIndex.loginOverlay};
  cursor: pointer;
  display: ${({ open }) => (open ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.7);
`;

const Wrap = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  bottom: 0;
  background-color: ${colors.white};
  width: 75%;
  max-width: 450px;
  padding: 30px 20px;
  transform: translateX(${({ open }) => (open ? 0 : "100%")});
  transition: 0.3s;
  z-index: ${zIndex.loginDrawer};

  @media ${mediaQuery.sm} {
    padding: 50px 55px;
  }

  a {
    color: ${colors.grey2};
    text-decoration: underline;
  }
`;
