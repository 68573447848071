import React, { FC, useCallback, useEffect } from "react";
import styled from "styled-components";
import { colors, containerWidths, headerHeight, mediaQuery, zIndex } from "shared/styles";
import { useHistory } from "react-router-dom";
import { ArrowLeft } from "./Icon";
import { Col, Container, Hidden, Row } from "react-grid-system";
import { useIsMobile } from "shared/hooks";
import { useSetRecoilState } from "recoil";
import { uiState } from "modules/UI";
import throttle from "lodash/throttle";

export const FullPageWrap: FC<any> = ({ children, ...rest }) => {
  return <Wrap {...rest}>{children}</Wrap>;
};

const Wrap = styled.div`
  position: relative;
  z-index: 50;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${headerHeight.sm}px;

  @media ${mediaQuery.md} {
    margin-top: ${headerHeight.lg}px;
  }
`;

type RouteType = "product" | "inner-page" | "upgrade" | "training";

export const OverlayRoute: FC<{ onBack?: () => void; match: any; type: RouteType }> = ({ children, onBack, type }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const setUiState = useSetRecoilState(uiState);
  const onScroll = useCallback(
    throttle((e) => {
      const pageScrolled = e?.target?.scrollTop || 0;
      setUiState((s) => ({ ...s, pageScrolled }));
    }, 100),
    [],
  );

  useEffect(() => {
    return () => {
      setUiState((s) => ({ ...s, pageScrolled: 0 }));
    };
  }, []);

  const onBackClick = () => {
    if (onBack) {
      return onBack();
    }

    history.goBack();
  };

  return (
    <>
      <Overlay className={`${type}-route-wrap`}>
        <ContentWrap onScroll={onScroll}>{children}</ContentWrap>
      </Overlay>
      <BackWrap className="overlay-back-wrap">
        <BackLink onClick={onBackClick}>
          <ArrowLeft color={isMobile ? colors.white : colors.grey2} size={isMobile ? 40 : 18} className="back-btn" />
          <Hidden xs sm>
            <span style={{ display: "inline-block", paddingLeft: 5 }}>Back</span>
          </Hidden>
        </BackLink>
      </BackWrap>
      <Sliver onClick={onBackClick} />
    </>
  );
};

export const overlayConfig = {
  xs: {
    left: "0px",
    headerLeft: "35px",
  },
  md: {
    left: "90px",
    headerLeft: "90px",
  },
  xxl: {
    left: "6%",
    headerLeft: "6%",
  },
};

const Sliver = styled.div`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  @media ${mediaQuery.md} {
    display: block;
    width: ${overlayConfig.md.left};
    z-index: ${zIndex.overlayPageBackBtn - 5};
  }

  @media ${mediaQuery.xxl} {
    width: ${overlayConfig.xxl.left};
  }
`;

const BackLink = styled.div`
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  display: flex;
  position: relative;
  color: ${colors.white};
  cursor: pointer;

  &:after {
    display: none;
  }

  &:hover {
    color: ${colors.primary};

    svg {
      fill: ${colors.primary};
    }
  }
`;

const BackWrap = styled.div`
  position: fixed;
  left: 5px;
  top: 10px;
  z-index: ${zIndex.overlayPageBackBtn};
  transition: 0.3s;

  @media ${mediaQuery.md} {
    left: 10px;
    top: 52px;
  }

  @media ${mediaQuery.mdMax} {
    .header-scrolling & {
      transform: translateY(-100%);
    }
  }

  .inner-page & {
    svg {
      fill: ${colors.black};
    }
  }
`;

const Overlay = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: ${overlayConfig.xs.left};
  z-index: ${zIndex.overlayPage};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media ${mediaQuery.md} {
    padding-left: ${overlayConfig.md.left};
  }

  @media ${mediaQuery.xxl} {
    padding-left: ${overlayConfig.xxl.left};
  }
`;

const ContentWrap = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: ${colors.white};

  @media ${mediaQuery.md} {
    border-radius: 10px 0 0 10px;
  }
`;

/**
 * Wrap for standard inner page in overlay format
 * Doesnt include product page
 */
export const InnerPage: FC<any> = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col>
          <InnerPageWrap>{children}</InnerPageWrap>
        </Col>
      </Row>
    </Container>
  );
};

const InnerPageWrap = styled.div`
  background-color: ${colors.white};
  flex: 1;
  position: relative;
  padding: 20px 0;
  padding-top: ${headerHeight.sm + 20}px;

  @media ${mediaQuery.md} {
    padding-top: ${headerHeight.lg + 50}px;
  }
`;

export const InnerBodyMax = styled.div`
  max-width: ${containerWidths.innerMaxWidth}px;
  box-sizing: content-box;

  @media ${mediaQuery.md} {
  }
  @media ${mediaQuery.xl} {
  }
`;
