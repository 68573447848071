import { sendAnalytic } from "modules/Api";
import React, { FC } from "react";
import { Col, Row } from "react-grid-system";
import { InfoCard } from "shared/components";

export const InfoCards = ({ cards }) => {
  if (!cards?.length) return null;

  const onInteract = async (id) => {
    await sendAnalytic({ id, type: "resource", event: "engagement" });
  };

  return (
    <Row>
      {cards.map((c, i) => (
        <Col md={6} lg={4} xl={3} key={i}>
          <InfoCard
            id={c?.id}
            title={c?.title}
            d_description={c?.d_description}
            link={c?.link}
            image={c?.image}
            btnText={c?.button}
            onClick={onInteract}
          />
        </Col>
      ))}
    </Row>
  );
};
