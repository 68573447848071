import React, { useEffect } from "react";
import { getStorage } from "modules/Storage";
import { ISession, sessionState } from "../state";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Loader } from "shared/components";
import { autoLogin } from "modules/Api/service";
import { useToast } from "shared/components/Toast/hook";

/**
 * if we haven't loaded in 10 seconds lets just send them to login
 * Something probably went wrong
 */
const KILLSWITCH = 10000;

export const Boot = ({ children }) => {
  const [sessionStore, setSessionState] = useRecoilState(sessionState);
  const toast = useToast({ type: "error", requiresDismiss: true });

  useEffect(() => {
    const fetch = async () => {
      try {
        const session: ISession | null = getStorage("session");

        if (session?.token) {
          const { data } = await autoLogin();

          // tried to auto login with either bad or expired to
          if (!data?.token || !data?.id) {
            setSessionState((s) => ({ ...s, resolved: true }));
            return;
          }

          const { token: newToken, ...newUser } = data;

          setSessionState((s) => ({
            ...s,
            token: newToken,
            user: { ...s.user, ...newUser },
            resolved: true,
          }));
        } else {
          setSessionState((s) => ({ ...s, resolved: true }));
        }
      } catch (e) {
        const error = e?.message
          ? `${e?.message}. Please refresh the page and try again`
          : "There was an error loading the app. Please refresh the page and try again";
        toast(error);
        setSessionState((s) => ({ ...s, resolved: true }));
      }

      setTimeout(() => {
        setSessionState((s) => ({ ...s, resolved: true }));
      }, KILLSWITCH);
    };

    fetch();
  }, []);

  return (
    <>
      {sessionStore.resolved ? (
        children
      ) : (
        <Loading>
          <Loader size={60} text="Loading..." />
        </Loading>
      )}
    </>
  );
};

const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
