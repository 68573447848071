import { routeConfig } from "modules/Router/routes";
import { atom, selector } from "recoil";

export type IHeaderMode = "normal" | "dim" | "invisible";
interface IUiState {
  headerMode: IHeaderMode;
  subscribeModalOpen: boolean;
  /**
   * css wrapper class
   */
  wrapperClass: string;
  overlayPageOpen: boolean;
  manualWrapperClass: string;
  /**
   * How much we scrolled a page
   */
  pageScrolled: number;
  isIdle: boolean;
}

const uiInitialState: IUiState = {
  headerMode: "normal",
  subscribeModalOpen: false,
  wrapperClass: "",
  overlayPageOpen: false,
  manualWrapperClass: "",
  pageScrolled: 0,
  isIdle: false,
};

export const uiState = atom<IUiState>({
  key: "ui",
  default: {
    ...uiInitialState,
  },
});

const routeToCss = (path) => {
  const wrapperClass = routeConfig[path]?.wrapperClass || "";
  const exploded = path.split("/").filter((s) => s);
  if (!exploded?.length) return "";

  const segments = exploded.join(" ");
  return `${segments} ${wrapperClass}`;
};

export const wrapperClassSelector = selector({
  key: "wrapperClass",
  get: ({ get }) => {
    const { wrapperClass } = get(uiState);
    return routeToCss(wrapperClass);
  },
});
