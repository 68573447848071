import React, { FC } from "react";
import styled from "styled-components";
import { H3 } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import { P } from "../Typography";
import { Field, FieldLabel } from "./Inputs";

const options = [
  { value: 1, label: "Very Negative" },
  { value: 2, label: "Negative" },
  { value: 3, label: "Neutral" },
  { value: 4, label: "Positive" },
  { value: 5, label: "Very Positive" },
];

interface IRadioPlus {
  onChange: (rating: number | string) => void;
  selected: number | string;
  label: string;
  options: Array<{ value: string | number; label: string }>;
}

/**
 * Works similar to a radio input group just a little fancier
 */
export const RadioPlus: FC<IRadioPlus> = ({ onChange, label, selected, options }) => {
  return (
    <Field>
      <FieldLabel>{label}</FieldLabel>
      <RadioWrap>
        {options?.length &&
          options.map((o) => (
            <Radio className={selected === o.value ? "active" : ""} onClick={() => onChange(o.value)} key={o.value}>
              <Dot>{o.value}</Dot>
              <RadioLabel align="center" variant="menu">
                {o.label}
              </RadioLabel>
            </Radio>
          ))}
      </RadioWrap>
    </Field>
  );
};

const RadioLabel = styled(P)`
  font-size: 10px;

  @media ${mediaQuery.md} {
    font-size: 14px;
  }
`;

const Dot = styled(H3)`
  display: inline-block;
  margin: 0 auto 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: ${colors.grey1};
  transition: 0.3s;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: ${colors.primary};
  }

  @media ${mediaQuery.md} {
    font-size: 36px;
    width: 78px;
    height: 78px;
    line-height: 78px;
  }
`;

const RadioWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @media ${mediaQuery.md} {
    justify-content: flex-start;
  }
`;

const Radio = styled.div`
  margin: 10px;
  cursor: pointer;
  text-align: center;
  width: 80px;

  &.active {
    h3 {
      color: #fff;
      background-color: ${colors.primary};
    }
  }

  @media ${mediaQuery.md} {
    margin: 20px;
  }

  @media ${mediaQuery.lg} {
    width: 150px;
    margin: 0;
    padding-top: 20px;
  }
  @media ${mediaQuery.xl} {
    width: 160px;
    margin: 0;
  }
`;
