import React, { FC, memo } from "react";
import { Overlay } from "shared/components";
import styled from "styled-components";
import Vimeo, { VimeoProps } from "@u-wave/react-vimeo";

// interface IBackgroundVideo extends HTMLVideoElement {
//   src: any;
//   poster: any;
//   renderOverlay?: () => React.ReactElement<any, any>;
// }

const defaultProps = {
  playsInline: true,
  muted: true,
  loop: true,
  autoPlay: true,
};

interface IBackgroundVimeoVideoProps extends VimeoProps {
  renderOverlay?: () => React.ReactElement<any, any> | null;
}

/**
 * Html 5 implementation
 */
export const BackgroundVimeoVideo: FC<IBackgroundVimeoVideoProps> = memo(({ renderOverlay, ...videoProps }) => {
  return (
    <Wrap>
      {renderOverlay ? renderOverlay() : <Overlay opacity={0.65} />}
      <VideoComp {...videoProps} background={true} />
    </Wrap>
  );
});

const Wrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

const VideoComp = styled(Vimeo)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
