import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { dashboardState } from "modules/Dashboard";

export const AuthedRouteWrap = ({ children }) => {
  const setDashboard = useSetRecoilState(dashboardState);

  useEffect(() => {
    setDashboard((d) => ({ ...d, dashboardLandingFocused: false }));
  }, []);

  return children;
};
