import React, { useCallback, useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useRecoilState } from "recoil";
import { colors, zIndex } from "shared/styles";
import styled from "styled-components";
import { Close } from "../Icon";
import { MenuLink } from "../Nav";
import { Portal } from "../Portal";
import { Label } from "../Typography";
import { toastState, toastInitialState, ToastType } from "./state";
import { Warning, Success, Error, Info } from "../Icon/index";

export const Toast = () => {
  const [toast, setToast] = useRecoilState(toastState);

  const onDismiss = useCallback(() => {
    // close the modal
    setToast((t) => ({ ...t, isOpen: false }));

    // let animation finish before resetting the rest of the toast
    setTimeout(() => {
      setToast(toastInitialState);
    }, 300);
  }, []);

  useEffect(() => {
    if (toast.isOpen && !toast.requiresDismiss) {
      setTimeout(onDismiss, toast.autoClose);
    }
  }, [toast.isOpen, toast.autoClose, onDismiss, toast.requiresDismiss]);

  const renderIcon = (type: ToastType) => {
    const props = {
      size: 20,
      color: colors.white,
    };
    switch (type) {
      case "warning":
        return <Warning {...props} />;
      case "success":
        return <Success {...props} />;
      case "error":
        return <Error {...props} />;
      default:
        return <Info {...props} />;
    }
  };

  return (
    <Wrap className={toast.type} open={toast.isOpen}>
      <Container>
        <Row>
          <Col>
            <Content>
              <IconWrap>{renderIcon(toast.type)}</IconWrap>
              <P color="white">{toast.message}</P>
            </Content>
          </Col>
        </Row>
      </Container>
      <CloseWrap>
        <MenuLink onClick={onDismiss}>
          <Close color={colors.white} size={24} />
        </MenuLink>
      </CloseWrap>
    </Wrap>
  );
};

const IconWrap = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const P = styled(Label)`
  margin-bottom: 0;
`;

const CloseWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Content = styled.div`
  padding: 30px 0 30px 30px;
  position: relative;
`;

const Wrap = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: ${zIndex.toast};
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  transform: translateY(${({ open }) => (open ? "0" : "100%")});

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.black};
    opacity: 0.7;
  }

  &.warning {
    &:before {
      background-color: ${colors.warn};
      opacity: 0.8;
    }
  }
  &.success {
    &:before {
      background-color: ${colors.success};
      opacity: 0.8;
    }
  }
  &.error {
    &:before {
      background-color: ${colors.error};
      opacity: 0.8;
    }
  }
`;
