import React, { FC } from "react";
import { PlayBtn } from "./Play";
import styled from "styled-components";
import ProgressCircle from "shared/components/ProgressCircle";
import { colors, mediaQuery } from "shared/styles";

const getProgress = (current, duration): number => {
  if (!current || !duration) return 0;

  return Math.round((current / duration) * 100);
};

export const PlayStatus: FC<{
  size: number;
  width: number;
  isPlaying: boolean;
  style?: any;
  currentTime: number;
  duration: number;
  hideProgress: boolean;
  onClick?: () => void;
}> = (props) => {
  const { size, width, isPlaying, style = {}, currentTime, duration, hideProgress, onClick = () => null } = props;

  return (
    <Wrap>
      {!hideProgress && (
        <ProgressCircle
          size={width}
          progress={getProgress(currentTime, duration)}
          strokeWidth={2}
          circleOneStroke={colors.grey2}
          circleTwoStroke={colors.primary}
        />
      )}
      <PlayBtn size={size} width={width} isPlaying={isPlaying} style={style} />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
`;
