export enum IRole {
  Dealer = "dealer",
  Admin = "admin",
  Guest = "guest",
}

export interface IUser {
  id: string | null;
  location: "CAN" | "USA";
  username: string;
  name: string;
  role: IRole;
  feedback: string | null;
  // ??
  dealer: any;
}
