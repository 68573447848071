import React, { FC, useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { ICard, Card, ArrowLeft, ArrowRight, P } from "shared/components";
import { mediaQuery } from "shared/styles";
import styled, { keyframes } from "styled-components";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRecoilValue } from "recoil";
import { dashboardState } from "modules/Dashboard";

SwiperCore.use([Mousewheel, Navigation]);

interface ICardProps {
  cards: ICard[];
  onChange: (active: number) => void;
  intro?: () => React.ReactElement<any, any>;
}

export const DesktopCardSlider: FC<ICardProps> = ({ cards, intro = () => null, onChange }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [interacted, setInteracted] = useState(false);
  const { tooltips } = useRecoilValue(dashboardState);

  // manually bump opacity for more control
  const introOpacity = (active): number => {
    switch (active) {
      case 0:
        return 1;
      case 1:
        return 0.1;
      default:
        return 0;
    }
  };

  return (
    <Wrap className={`card-slider card-slider-desktop ${interacted ? "interacted" : ""}`}>
      <IntroWrap style={{ opacity: introOpacity(activeSlide) }}>{intro()}</IntroWrap>
      {cards?.length && (
        <Swiper
          spaceBetween={30}
          mousewheel={true}
          navigation={{
            nextEl: ".next",
            prevEl: ".back",
          }}
          freeMode={true}
          slidesPerView={"auto"}
          onSlideChange={({ activeIndex }) => {
            setActiveSlide(activeIndex);
            setInteracted(true);
            onChange(activeIndex);
          }}>
          <SwiperSlide className="intro-slide"></SwiperSlide>
          <SwiperSlide className="intro-slide"></SwiperSlide>
          {cards.map((card, i) => (
            <SwiperSlide key={i}>
              <CardWrap>
                <Card {...card} />
              </CardWrap>
            </SwiperSlide>
          ))}
          <SwiperSlide className="end-slide"></SwiperSlide>
          <NavigationWrap className={`navigation-wrap ${tooltips.sliderScroll ? "slider-scrolled" : ""}`}>
            <Container>
              <Row justify={"end"}>
                <Col>
                  <ArrowWrap>
                    <LeftWrap className="arrow">
                      <ArrowLeft size={20} color="white" />
                    </LeftWrap>
                    <Label color="white">Scroll</Label>
                    <RightWrap className="arrow">
                      <ArrowRight size={20} color="white" />
                    </RightWrap>
                  </ArrowWrap>
                </Col>
              </Row>
            </Container>
          </NavigationWrap>
        </Swiper>
      )}
    </Wrap>
  );
};

const left = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
`;

const right = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
`;

const Label = styled(P)`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
`;

const LeftWrap = styled.div`
  animation: 2s ${left} ease-out infinite;
`;
const RightWrap = styled.div`
  animation: 2s ${right} ease-out infinite;
`;

const ArrowWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NavigationWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(-250px);
  transition: 0.3s;

  .arrow {
    margin: 0 5px;
    padding-top: 5px;
    opacity: 0.2;
  }

  &.slider-scrolled {
    .arrow {
      animation: none;
    }
  }
`;

const IntroHolder = styled.div``;

const IntroWrap = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s opacity;
`;

const Wrap = styled.div`
  width: 100%;

  .swiper-slide {
    display: block;
    width: 265px;
  }

  .prev,
  .next {
    display: inline-block;
    width: 100px;
    height: 100px;
  }

  .prev {
    background-color: green;
    right: 200px;
  }
  .next {
    background-color: blue;
  }

  .swiper-container {
    height: calc(80vh - 50px);
  }

  .intro-slide {
    width: calc(33%);

    @media ${mediaQuery.xl} {
      width: calc(16% + 80px);
    }

    @media ${mediaQuery.xxl} {
      width: calc(17% + 180px);
    }
  }

  .end-slide {
    width: 50px;
  }
`;

const CardWrap = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
