import React, { FC, memo } from "react";
import { BackgroundVimeoVideo } from "shared/components";

interface IVimeoBRoll {
  renderOverlay: () => React.ReactElement<any, any> | null;
  videoId?: string;
}

/**
 * Main vimeo b-roll used on app landing and dashboard (authed landing)
 */
export const VimeoBRoll: FC<IVimeoBRoll> = memo(({ renderOverlay, videoId = "489005283" }) => {
  return (
    <BackgroundVimeoVideo
      muted
      autoplay={true}
      controls={false}
      loop={true}
      video={videoId}
      renderOverlay={renderOverlay}
    />
  );
});
