import React, { FC } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { useRecoilValue } from "recoil";
import { sessionState } from "modules/Auth";

export const ErrorBoundary = ({ children }) => {
  const { user } = useRecoilValue(sessionState);
  return (
    <SentryErrorBoundary
      fallback={Fallback}
      beforeCapture={(scope) => {
        const id = user?.id || "";
        scope.setUser({ id });
      }}>
      {children}
    </SentryErrorBoundary>
  );
};

const Fallback = () => {
  return (
    <div
      style={{
        backgroundColor: "#000",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}>
      <div style={{ width: "90%", margin: "0 auto", maxWidth: 500 }}>
        <h2 style={{ color: "#fff " }}>Whoops there was a problem!</h2>
        <p style={{ color: "#fff" }}>
          Please try refreshing your browser to resolve the issue. If the problem persists please contact Valor directly
          at{" "}
          <a href="mailto:info@valorfireplaces.com" style={{ textDecoration: "underline", color: "#fff" }}>
            info@valorfireplaces.com
          </a>
        </p>
      </div>
    </div>
  );
};
