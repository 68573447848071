import React, { FC, useCallback, useEffect, useRef } from "react";
import { BackgroundVideo, Overlay } from "shared/components";
import LandingVideo from "assets/videos/connect-welcome.mp4";
import LandingPoster from "assets/videos/connect-welcome.jpg";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { landingState } from "../state";

/**
 * Html 5 implementation
 */
export const Video = () => {
  const { videoMode, muted, isPlaying } = useRecoilValue(landingState);
  const setLandingState = useSetRecoilState(landingState);
  const overlayOpacity = (videoMode === "active" || videoMode === "theater") && isPlaying ? 0 : 0.8;
  const player = useRef<HTMLVideoElement>(null);

  const videoEnded = useCallback(() => {
    setLandingState((state) => ({ ...state, videoMode: "finished", loginOpen: true, isPlaying: false }));
  }, [setLandingState]);

  const destroyListeners = () => {
    if (player?.current) {
      player.current?.removeEventListener("ended", videoEnded);
      player.current?.removeEventListener("timeupdate", timeUpdated);
    }
  };

  const timeUpdated = useCallback(
    (e) => {
      const videoCurrentTime = Math.round(player?.current?.currentTime || 0);
      setLandingState((state) => ({ ...state, videoCurrentTime }));
    },
    [setLandingState],
  );

  useEffect(() => {
    if (player.current) {
      if (isPlaying) {
        player.current.play();
      } else {
        player.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    const readyState = player?.current?.readyState || 0;
    if (readyState > 0) {
      const videoDuration = Math.round(player?.current?.duration || 0);
      setLandingState((state) => ({ ...state, videoDuration, hasLoaded: true }));
    }
  }, [player?.current?.readyState, setLandingState]);

  useEffect(() => {
    if (player.current) {
      player.current.addEventListener("ended", videoEnded, false);
      player.current.addEventListener("timeupdate", timeUpdated, false);
    }

    return destroyListeners;
  }, [player, videoEnded, timeUpdated]);

  return (
    <BackgroundVideo
      controls={false}
      src={LandingVideo}
      poster={LandingPoster}
      videoRef={player}
      muted={muted}
      loop={false}
      renderOverlay={() => <Overlay opacity={overlayOpacity} style={{ cursor: "none" }} />}
    />
  );
};
