export const setStorage = (key: string, data: any) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const getStorage = (key: string) => {
  const item = localStorage.getItem(key);
  if (!item) return null;

  return JSON.parse(item);
};
