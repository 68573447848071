import React, { useState } from "react";
import { FeatureTabs } from "modules/Dashboard/components";
import { Route, useLocation, Switch } from "react-router-dom";
import { Routes } from "modules/Router/routes";
import { Product } from "./Product";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "modules/Router/router.css";
import { Subscribe } from "modules/Subscribe";
import { ThankYou } from "./ThankYou";
import { Feedback } from "./Feedback";
import { Portal } from "shared/components";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { dashboardState, AuthedRouteWrap } from "modules/Dashboard";
import { Helmet } from "react-helmet";
import { Upgrade } from "./Upgrade";
import { Training } from "./Training";
import { VimeoBRoll } from "modules/Landing";
import { useGet } from "shared/hooks";
import { endpoints, IDashboardResponse } from "modules/Api";

export const Dashboard = () => {
  const { interactedWithTabs } = useRecoilValue(dashboardState);
  const [changed, setChanged] = useState(false);
  const { data, loading } = useGet<IDashboardResponse>(endpoints.dashboard);

  return (
    <>
      {!loading && <VimeoBRoll videoId={data?.welcome?.intro?.bRoll} renderOverlay={() => null} />}
      <Wrap className="dashboard-wrap">
        <Helmet>
          <title>Valor Connect</title>
        </Helmet>
        <FeatureTabs onChange={() => setChanged(true)} data={data} loading={loading} />
        <AuthedRoutes />
        <Portal>
          <Subscribe />
        </Portal>
      </Wrap>
    </>
  );
};

const AuthedRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition timeout={300} classNames="route" key={location.key}>
        <Switch location={location}>
          <Route
            exact
            path={`${Routes.Products}/:id`}
            render={(props) => (
              <AuthedRouteWrap>
                <Product {...props} />
              </AuthedRouteWrap>
            )}
          />
          <Route
            exact
            path={`${Routes.Upgrades}/:id`}
            render={(props) => (
              <AuthedRouteWrap>
                <Upgrade {...props} />
              </AuthedRouteWrap>
            )}
          />
          <Route
            exact
            path={`${Routes.Training}/:id`}
            render={(props) => (
              <AuthedRouteWrap>
                <Training {...props} />
              </AuthedRouteWrap>
            )}
          />
          <Route
            exact
            path={Routes.ThankYou}
            render={(props) => (
              <AuthedRouteWrap>
                <ThankYou {...props} />
              </AuthedRouteWrap>
            )}
          />
          <Route
            exact
            path={Routes.Feedback}
            render={(props) => (
              <AuthedRouteWrap>
                <Feedback {...props} slug={"feedback"} />
              </AuthedRouteWrap>
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Wrap = styled.div`
  position: relative;
  z-index: 10;
  height: 100%;
`;
