import { AxiosRequestConfig } from "axios";
import { IIntro, IItem, ITabData } from "modules/Dashboard";

export const endpoints = {
  login: "/login",
  // guest landing route
  landing: "/landing",

  // authed routed below
  // auto login, checks token and sees if it's valid
  auth: "/auth",
  user: "/user",
  dashboard: "/dashboard",
  products: "/products",
  upgrades: "/upgrades",
  training: "/training",
  analytics: "/analytics",
  innerPage: "/inner-page",

  // admin endpoints
  engagementOverview: "/admin/engagement/overview",
  engagementPerforming: "/admin/engagement/performing",
  dealers: "/dealers",
};

export interface IAPIResponse<T = any> {
  data: T;
  status?: number;
  statusText?: string;
  headers?: any;
  config?: AxiosRequestConfig;
  request?: any;
}

export interface IVideo {
  id: string;
  // video id
  vimeo: string;
  title: string;
  desc?: string;
  image?: string;
}

interface ISummary {
  title: string;
  desc?: string;
  image?: string;
  banner?: string;
}

export interface IQuestion {
  id: string;
  question: string;
  answer: string;
}

export interface IInfoCard {
  id: string;
  title: string;
  d_description: string;
  image?: string;
  // internal link or external link, we use href if link includes protocol
  link?: string;
  btnText?: string;
  onClick: (id) => void;
}

type SectionType = "videos" | "cards" | "faqs" | "poll";

// shared part of a section
interface ISection {
  // unique id to identify section can be anything just unique, for tabs
  id: string;
  // tab title
  title: string;
  type: SectionType;
}

export interface IVideoSection extends ISection {
  data: {
    videos: IVideo[];
  };
}
export interface ICardSection extends ISection {
  data: {
    cards: IInfoCard[];
  };
}
export interface IFaqSection extends ISection {
  data: {
    faqs: IQuestion[];
  };
}

interface IPoll {
  id: string;
  url: string;
}
export interface IPollSection extends ISection {
  data: {
    polls: IPoll[];
  };
}

export type Section = IVideoSection | ICardSection | IFaqSection | IPollSection;

// Response from products, upgrades and technical training
export interface IResourceResponse {
  // page/resource id
  id: string;
  summary: ISummary;
  // background meta about a page or non rendered stuff?
  // meta?: IMeta;
  sections: Section[];
}
export interface IInnerPageResponse {
  title: string;
  desc: string;
}
export interface ILandingResponse {
  title: string;
  desc: string;
  bRoll?: string;
  landingVideo?: string;
}

interface IWelcome {
  intro: IIntro & { bRoll?: string };
  items?: IItem[];
}

export interface IDashboardResponse {
  welcome: IWelcome;
  products: ITabData;
  upgrades: ITabData;
  training: ITabData;
}

// used for generic page template
export interface IGenericPageResponse {
  id: string;
  // summary: ISummary;
  // this could be just raw html to render to the screen??
  data: {
    // title to render
    title: string;
    // paragraphs for the page
    body: string;
  };
}

/**
 * Admin related api stuff
 */

export interface IMetric {
  title: string;
  count: number;
}

export interface IResourceWithAnalytics<T> {
  views: number;
  engagements: number;
  data: T;
}

export interface IEngagementOverviewResponse {
  overview: IMetric[];
  analytics: {
    performing: IResourceWithAnalytics<IVideo>[];
  };
}

interface IStatDetail {
  type: string;
  section: string;
  title: string;
  count: number;
}

export interface IDealer {
  id: string;
  name: string;
  email: string;
  desc: string;
  country: "CAN" | "USA";
  stats: {
    product: number;
    training: number;
    upgrade: number;
    polls: number;
    resource: number;
    detailed: IStatDetail[];
  };
}

export interface IDealersResponse {
  dealers: IDealer[];
}
