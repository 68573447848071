import React, { FC } from "react";
import { P, H2, ICard } from "shared/components";
import { Col, Container, Row } from "react-grid-system";
import { IIntro } from "../FeatureTabs";
import { MobileCardSlider } from "./MobileCardSlider";
import { useIsMobile } from "shared/hooks";
import { DesktopCardSlider } from "./DeskCardSlider";
import styled from "styled-components";
import { mediaQuery } from "shared/styles";

export const Intro = ({ subTitle = "", title = "", desc = "" }) => (
  <div className="intro">
    <Container>
      <Row>
        <Col md={8} xl={6} xxl={6}>
          <ContentWrap className="intro-content-wrap">
            <P variant="menu" color="white">
              {subTitle}
            </P>
            <H2 color="white">{title}</H2>
            <IntroParagraph color="white">{desc}</IntroParagraph>
          </ContentWrap>
        </Col>
      </Row>
    </Container>
  </div>
);

const IntroParagraph = styled(P)`
  font-size: 16px;
`;

const ContentWrap = styled.div`
  max-width: 485px;
  box-sizing: content-box;

  @media ${mediaQuery.xxl} {
    padding-left: 100px;
  }
`;

interface ITabContent {
  cards: ICard[];
  intro?: IIntro;
  onInteraction: (active) => void;
}
export const TabSlider: FC<ITabContent> = ({ intro = {}, cards, onInteraction }) => {
  const isMobile = useIsMobile();

  // we need seperate experiences for mobile vs desktop
  const SliderComponent = isMobile ? MobileCardSlider : DesktopCardSlider;

  return (
    <SliderComponent
      cards={cards || []}
      intro={() => <Intro subTitle={intro?.subTitle} title={intro?.title} desc={intro?.desc} />}
      onChange={onInteraction}
    />
  );
};
