import React, { useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { uiState, wrapperClassSelector } from "../state";
import { colors, fontFamily } from "shared/styles";
import { Idle } from "./Idle";
import { useIsLoggedIn } from "modules/User/hooks";
import { Helmet } from "react-helmet";
import { landingState } from "modules/Landing";

export const Wrap = ({ children }) => {
  const wrapperClass = useRecoilValue(wrapperClassSelector);
  const { manualWrapperClass, headerMode, pageScrolled } = useRecoilValue(uiState);
  const { loginOpen } = useRecoilValue(landingState);
  const setUiState = useSetRecoilState(uiState);

  const onIdleChange = (isIdle) => {
    setUiState((s) => ({ ...s, isIdle }));
  };

  const getScrolledClass = useCallback(() => {
    if (pageScrolled > 20) {
      return "header-scrolling";
    }

    return "";
  }, [pageScrolled]);

  const loginClass = loginOpen ? "login-open" : "";

  return (
    <Wrapper
      className={`${wrapperClass} ${manualWrapperClass} header-${headerMode} ${getScrolledClass()} ${loginClass}`}>
      <Helmet>
        <title>Valor Connect</title>
        <meta name="description" content="Valor Connect" />
      </Helmet>
      <Idle onIdleChange={onIdleChange}>{children}</Idle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  font-family: ${fontFamily};
  background-color: ${colors.black};

  a {
    color: ${colors.primary};

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    padding-left: 25px;
  }

  &.header-invisible {
    .video-cursor {
      opacity: 0;
    }
  }

  .router-link.active {
    pointer-events: none;
  }

  &.modal-open,
  &.login-open {
    .header-wrap {
      opacity: 0;
    }

    .featured-tabs {
      opacity: 0.1;
    }
  }
`;
