import { colors, fontFamily } from "shared/styles";
import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  margin-bottom: 20px;
  color: ${colors.black};
  border: 0;
  border-collapse: collapse;

  &.light {
    td,
    th {
      color: ${colors.white};
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  &.row-highlight {
    tr:hover {
      td,
      th {
        cursor: pointer;
        background-color: ${colors.grey1};
      }
    }
  }

  &.row-highlight.light {
    tr:hover {
      td,
      th {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

export const Tr = styled.tr``;

const tdShared = (props) => {
  const width = props?.width ? (isNaN(props?.width) ? props?.width : `${props?.width}px`) : "";
  const align = props?.align || "left";
  const display = props?.hidden || "table-cell";
  return `
  font-family: ${fontFamily};
  padding: 15px 5px;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px; 
  width: ${width};
  text-align: ${align};
  border: 0;
  border-bottom: 1px solid ${colors.grey1};
  vertical-align: top;
  position: relative;
  display: ${display};
`;
};

interface TdThProps {
  width?: number | string;
  align?: "left" | "center" | "right";
  hidden?: boolean;
}

export const Th = styled.th<TdThProps>`
  ${(props) => tdShared(props)}
  font-weight: 700;
  padding: 15px 5px;
`;

export const Td = styled.td`
  ${(props) => tdShared(props)}
`;
