import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { Tabs, ITab, Loader, OverviewAnalytic } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import { Videos } from "./tabs/Videos";
import { InfoCards } from "./tabs/InfoCards";
import { Faqs } from "./tabs/Faqs";
import { Poll } from "./tabs/Poll";
import {
  IResourceResponse,
  Section,
  IVideoSection,
  ICardSection,
  IFaqSection,
  IPollSection,
  sendAnalytic,
} from "modules/Api";

interface IResourceTabs {
  data: IResourceResponse | null;
  loading: boolean;
}

const mapDataToComponent = (section: Section, pageId: string): ITab => {
  const shared = {
    key: section?.id,
    label: section?.title,
  };

  switch (section.type) {
    case "videos":
      return {
        ...shared,
        disableContentContainer: true,
        content: () => (
          <OverviewAnalytic type={"page"} event={"video"} id={pageId}>
            <Videos videos={(section as IVideoSection)?.data?.videos || []} />
          </OverviewAnalytic>
        ),
      };
    case "cards":
      return {
        ...shared,
        content: () => (
          <OverviewAnalytic type={"page"} event={"resource"} id={pageId}>
            <InfoCards cards={(section as ICardSection)?.data?.cards || []} />
          </OverviewAnalytic>
        ),
      };
    case "faqs":
      return {
        ...shared,
        content: () => (
          <OverviewAnalytic type={"page"} event={"faq"} id={pageId}>
            <Faqs questions={(section as IFaqSection)?.data?.faqs || []} />
          </OverviewAnalytic>
        ),
      };
    case "poll":
      return {
        ...shared,
        content: () => (
          <OverviewAnalytic type={"page"} event={"poll"} id={pageId}>
            <Poll
              url={(section as IPollSection)?.data?.polls?.[0]?.url || ""}
              id={(section as IPollSection)?.data?.polls?.[0]?.id || ""}
            />
          </OverviewAnalytic>
        ),
      };

    default:
      return null as any;
  }
};

const hasData = (data) => {
  if (!data) return false;

  const [subData] = Object.values(data);
  return (subData as any)?.length > 0;
};

export const ResourceTabs: FC<IResourceTabs> = ({ loading, data }) => {
  const tabs: ITab[] = (data?.sections || [])
    .filter((s) => s?.type && s?.id && s?.title && hasData(s?.data))
    .map((s) => mapDataToComponent(s, data?.id || ""))
    .filter((s) => s);

  useEffect(() => {
    if (data?.id) {
      sendAnalytic({ id: data?.id, type: "page", event: "product" });
    }
  }, [data?.id]);

  return (
    <BodyContent className="resource-body">
      {loading ? (
        <LoaderWrap>
          <Loader size={60} />
        </LoaderWrap>
      ) : (
        <Tabs tabs={tabs} defaultTab={tabs?.[0].key} />
      )}
    </BodyContent>
  );
};

const LoaderWrap = styled.div`
  margin-top: 50px;
`;

const BodyContent = styled.div`
  background-color: ${colors.white};
  flex: 1;
  position: relative;
  padding: 20px 0;

  @media ${mediaQuery.md} {
    padding: 25px 0;
  }
`;
