import { atom } from "recoil";

/**
 * active = user click video to play, sound turns on overlay disappears
 * theater = when video is playing and user is inactive on the screen for x seconds
 *            video goes full screen and we hide header
 * destroyed = its been removed  (once user logs in)
 */
export type IVideoState = "active" | "theater" | "finished";

interface ILanding {
  videoMode: IVideoState;
  muted: boolean;
  isPlaying: boolean;
  loginOpen: boolean;
  videoDuration: number;
  videoCurrentTime: number;
  hasLoaded: boolean;
}

export const landingInitialState: ILanding = {
  videoMode: "active",
  muted: false,
  isPlaying: true,
  loginOpen: false,
  videoDuration: 0,
  videoCurrentTime: 0,
  hasLoaded: false,
};

export const landingState = atom<ILanding>({
  key: "landing",
  default: {
    ...landingInitialState,
  },
});
