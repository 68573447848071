import React, { FC } from "react";
import styled from "styled-components";
import { colors, mediaQuery } from "shared/styles";

export const Menu = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;

  li:last-child {
    margin-right: 0;
  }

  padding-top: 12px;
  @media ${mediaQuery.md} {
    padding-top: 18px;
  }
`;

export const MenuItem = styled.li`
  margin: 0 7px;

  &:last {
    margin-right: 0;
  }

  @media ${mediaQuery.md} {
    margin: 0 10px;
  }
`;

export const MenuLink = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.white};
  display: inline-block;
  transition: 0.2s;
  align-items: center;
  display: flex;
  position: relative;

  svg {
    transition: 0.2s;
  }

  &:hover {
    cursor: pointer;
  }

  @media ${mediaQuery.md} {
    &:after {
      content: "";
      display: block;
      height: 1px;
      background-color: ${colors.white};
      position: absolute;
      top: 100%;
      margin-top: 5px;
      left: 6px;
      right: 100%;
      transition: 0.3s;
      opacity: 0;
    }

    &:hover&:after {
      right: 6px;
      opacity: 1;
    }
  }
`;

export const DropdownMenuLink = styled(MenuLink)`
  text-align: left;
  padding: 10px 17px;
  display: block;
  width: 100%;

  &:hover {
    color: ${colors.white};
    background: rgba(0, 0, 0, 0.4);
  }

  &:after {
    display: none;
  }
`;
