import React, { useCallback, useEffect, useState } from "react";
import { colors, mediaQuery, headerHeight, zIndex } from "shared/styles";
import { sessionState } from "modules/Auth";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { uiState, IHeaderMode } from "modules/UI";
import { useIsLoggedIn } from "modules/User/hooks";
import { HeaderComponent } from "./Header";
import styled from "styled-components";
import { overlayConfig } from "shared/components";
import debounce from "lodash/debounce";
import { deviceType } from "detect-it";
import { landingState } from "modules/Landing";

interface IHeader {
  isDim: boolean;
  isLoggedIn: boolean;
}

export const Header = () => {
  const isLoggedIn = useIsLoggedIn();
  const { user } = useRecoilValue(sessionState);
  const { isIdle, pageScrolled, headerMode } = useRecoilValue(uiState);
  const setUiState = useSetRecoilState(uiState);
  // when user hovers over header re-enabled
  const [interactedWith, setInteractedWith] = useState(deviceType === "mouseOnly" ? false : true);

  useEffect(() => {
    const dimToActive = () => {
      const headerMode = "normal";
      setUiState((c) => ({ ...c, headerMode }));
    };

    const activeToDim = () => {
      const headerMode = isLoggedIn ? "dim" : "invisible";
      setUiState((c) => ({ ...c, headerMode }));
    };

    // mouse users
    if (!isIdle && interactedWith) {
      dimToActive();
      setInteractedWith(false);
    }

    // touch users
    if (!isIdle && deviceType !== "mouseOnly") {
      dimToActive();
    }

    // non logged in the menu can dis
    if (!isIdle && !isLoggedIn) {
      dimToActive();
    }

    if (isIdle) {
      activeToDim();
    }
  }, [isIdle, isLoggedIn, interactedWith, setUiState]);

  const getScrolledClass = useCallback(() => {
    if (pageScrolled > 20) {
      return "scrolling";
    }

    return "";
  }, [pageScrolled]);

  const onMouseOver = debounce((e) => {
    setInteractedWith(true);
  }, 50);

  return (
    <HeaderWrap className={`header-wrap ${headerMode} ${getScrolledClass()}`} onMouseOver={onMouseOver}>
      <HeaderComponent isLoggedIn={isLoggedIn} username={user?.username} />
    </HeaderWrap>
  );
};

const HeaderWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  height: ${headerHeight.sm}px;
  transition: 0.3s;
  transform: translateY(0);
  display: flex;
  align-items: center;
  z-index: ${zIndex.header};

  &.invisible,
  &.scrolling {
    transform: translateY(-100%);
    opacity: 0;
  }

  &.dim {
    opacity: 0.2;
  }

  .logo {
    fill: ${colors.primary};
  }

  .overlay-route &,
  .upgrades &,
  .training &,
  .products & {
    left: ${overlayConfig.xs.headerLeft};

    @media ${mediaQuery.md} {
      left: ${overlayConfig.md.headerLeft};
    }
    @media ${mediaQuery.xxl} {
      left: ${overlayConfig.xxl.headerLeft};
    }
  }

  .upgrades &,
  .training &,
  .products & {
    .logo {
      fill: ${colors.white};
    }
  }

  .inner-page & {
    .menu a,
    .menu button {
      color: ${colors.black};
    }

    .dropdown-menu svg {
      fill: ${colors.black};
    }

    .dropdown-body {
      background-color: rgba(0, 0, 0, 0.8);
    }

    .dropdown-body a,
    .dropdown-body button {
      color: ${colors.white};
    }
  }

  @media ${mediaQuery.md} {
    height: ${headerHeight.lg}px;
  }
`;
