import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { colors, fontFamily, IColors } from "shared/styles";
import styled, { css } from "styled-components";
import { Loader } from "./Loader";

interface IButtonProps {
  variant?: "primary" | "secondary" | "inverse" | "text" | "white";
  size?: "md" | "lg";
  color?: IColors;
  /**
   * The URL to link to when the button is clicked. If defined, an a element will be used as the root node.
   */
  href?: string;
  /**
   * If true, the button will take up the full width of its container.
   */
  disabled?: boolean;
  onClick?: () => void;
  /**
   * Set target attribute when using href (anchor tag)
   */
  target?: string;
  /**
   * loading state for button
   */
  isLoading?: boolean;
  type?: "button" | "submit";
  className?: string;
}

const getSize = (props) => {
  const config = {
    // sm: css<IButtonProps>`
    //   padding: 10px 16px;
    //   font-size: 14px;
    //   line-height: 16px;
    //   letter-spacing: 0.08em;
    //   font-weight: 600;
    //   border-radius: 54px;
    // `,
    md: css<IButtonProps>`
      padding: 10px 16px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.08em;
      font-weight: 600;
      border-radius: 54px;
    `,
    lg: css<IButtonProps>`
      padding: 11px 18px 10px 18px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.08em;
      font-weight: 600;
      border-radius: 60px;
    `,
  };

  return config[props.size || "lg"];
};

const getVariation = (props) => {
  const config = {
    text: css<IButtonProps>`
      background: transparent;
      border: none;
      outline: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-decoration: none;
      color: ${colors.grey4};

      &:hover {
        background-color: ${colors.grey1};
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.12);
      }

      &.active {
        background-color: ${colors.grey1};
      }

      &:disabled,
      .disabled {
        color: ${colors.grey2};
      }
    `,
    primary: css<IButtonProps>`
      background-color: transparent;
      color: ${colors.primary};
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        color: ${colors.grey4};
        background-color: rgba(0, 0, 0, 0.06);
        border-color: rgba(0, 0, 0, 0.06);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.12);
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.08);
      }

      &:disabled,
      .disabled {
        color: ${colors.grey2};
      }
    `,
    secondary: css<IButtonProps>`
      background-color: transparent;
      color: ${colors.grey2};
      border: 1px solid ${colors.grey1};

      &:hover {
        background-color: ${colors.grey1};
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.12);
      }

      &.active {
        background-color: ${colors.grey1};
      }

      &:disabled,
      .disabled {
        color: rgba(0, 0, 0, 0.2);
      }
    `,
    white: css<IButtonProps>`
      background-color: ${colors.white};
      color: ${colors.primary};

      &:hover {
        color: ${colors.white};
        background-color: ${colors.grey4};
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.12);
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.08);
      }

      &:disabled,
      .disabled {
        color: ${colors.grey2};
      }
    `,
    inverse: css<IButtonProps>`
      background-color: transparent;
      color: ${colors.white};
      border: 1px solid rgba(255, 255, 255, 0.1);

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &.active {
        border: 1px solid rgba(255, 255, 255, 0.1);
      }

      &:disabled,
      .disabled {
        color: rgba(255, 255, 255, 0.6);
      }
    `,
  };

  return config[props.variant || "primary"];
};

const nullHandler = () => null;

const ATag = (props) => <a {...props}>{props.children || ""}</a>;
const ButtonTag = (props) => <button {...props}>{props.children || ""}</button>;
const LinkTag = (props) => (
  <NavLink to={props?.href}>
    <ButtonTag {...props}>{props.children || ""}</ButtonTag>
  </NavLink>
);

const BaseButton: FC<IButtonProps> = (props) => {
  const { children, isLoading, ...rest } = props;

  const Tag = props.href ? (/^https:\/\//.test(props.href) ? ATag : LinkTag) : ButtonTag;

  return (
    <Tag {...rest}>
      {children}
      {isLoading && (
        <LoaderWrap>
          <Loader size={20} />
        </LoaderWrap>
      )}
    </Tag>
  );
};

BaseButton.defaultProps = {
  variant: "primary",
  size: "lg",
  disabled: false,
  onClick: nullHandler,
  color: undefined,
  href: undefined,
};

export const Button = styled(BaseButton)`
  font-family: ${fontFamily};
  display: inline-block;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  border: none;
  outline: none;
  pointer-events: ${({ disabled, isLoading }) => (disabled || isLoading ? "none" : "auto")};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }

  ${(props) => getSize(props)};
  ${(props) => getVariation(props)};
`;

export const ButtonWrap = styled.div<{ align?: "left" | "center" | "right" }>`
  margin: 20px 0;
  text-align: ${({ align }) => align || "center"};
`;

const LoaderWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
