import React, { FC, useEffect, useState } from "react";
import { FlashMessage, P, VimeoPlayer, ArrowLeftThin, ArrowRightThin, H3 } from "shared/components";
import { colors, mediaQuery, containerWidths } from "shared/styles";
import styled from "styled-components";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { sendAnalytic, IVideo } from "modules/Api";
import { Col, Container, Row } from "react-grid-system";
import { useIsMobile } from "shared/hooks";

SwiperCore.use([Navigation, Mousewheel]);

interface IVideos {
  videos: IVideo[];
}
export const Videos: FC<IVideos> = ({ videos }) => {
  const filtered = (videos || []).filter((v) => v?.id);
  const [activeSlide, setActiveSlide] = useState(0);
  const [viewTracked, setViewTracked] = useState<string[]>([]);
  const onPlay = async (e, id) => {
    if (viewTracked.includes(id)) return;

    try {
      await sendAnalytic({ id, type: "video", event: "engagement" });
      setViewTracked((a) => [...a, id]);
    } catch (e) {
      // fail silently here
      console.warn("unable to send event");
    }
  };

  return (
    <Wrap className="video-slider">
      <NavWrap>
        <Container>
          <Row>
            <Col xs={12}>
              <NavigationWrap className="navigation-wrap">
                <LeftWrap className="arrow back">
                  <ArrowLeftThin size={26} color={colors.primary} />
                </LeftWrap>
                <RightWrap className="arrow next">
                  <ArrowRightThin size={26} color={colors.primary} />
                </RightWrap>
              </NavigationWrap>
            </Col>
          </Row>
        </Container>
      </NavWrap>
      {filtered?.length ? (
        <Swiper
          spaceBetween={40}
          slidesPerView={"auto"}
          mousewheel={{
            forceToAxis: true,
          }}
          centeredSlides={true}
          // lazy={true}
          watchSlidesVisibility={true}
          onSlideChange={({ activeIndex }) => {
            setActiveSlide(activeIndex);
          }}
          navigation={{
            nextEl: ".next",
            prevEl: ".back",
          }}>
          {filtered.map((v, i) => (
            <SwiperSlide key={`${v.id}-${i}`} className="video-slide">
              <VideoWrap>
                <VimeoPlayer
                  video={v.vimeo}
                  onPlay={(e) => onPlay(e, v.id)}
                  image={v?.image}
                  active={activeSlide === i}
                />
              </VideoWrap>
              <Container>
                <Row>
                  <Col xs={12}>
                    <Content>
                      <H3>{v?.title || ""}</H3>
                      <VideoP mb={0}>{v?.desc || ""}</VideoP>
                    </Content>
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <FlashMessage withContainer>
          <P mb={0}>No videos found for this product</P>
        </FlashMessage>
      )}
    </Wrap>
  );
};

const SpacerSlider = styled.div`
  width: 100%;
  height: 300px;
  background-color: red;
`;

const Wrap = styled.div`
  width: 100%;
  position: relative;
  .video-slide {
    display: block;
    width: 95%;
    max-width: 900px;
  }

  @media ${mediaQuery.md} {
    .video-slide {
      width: 85%;
    }
  }
`;

const Content = styled.div`
  max-width: 90%;
  margin-left: -15px;

  @media ${mediaQuery.md} {
    max-width: 100%;
  }
`;

const VideoWrap = styled.div`
  overflow: hidden;
  border-radius: 5px;
  background-color: ${colors.grey1};
  margin: 0 0 15px;

  @media ${mediaQuery.md} {
    border-radius: 15px;
  }
`;

const NavWrap = styled.div`
  display: block;
  position: absolute;
  right: 0;
  top: -55px;
  width: 90px;
  z-index: 10;

  @media ${mediaQuery.sm} {
    right: 50%;
    margin-right: -${containerWidths.sm / 2 - 15}px;
    width: 120px;
  }
  @media ${mediaQuery.md} {
    margin-right: -${containerWidths.md / 2 - 15}px;
  }
  @media ${mediaQuery.lg} {
    margin-right: -${containerWidths.lg / 2 - 15}px;
  }
  @media ${mediaQuery.xl} {
    margin-right: -${containerWidths.xl / 2 - 15}px;
  }
  @media ${mediaQuery.xxl} {
    margin-right: -${containerWidths.xxl / 2 - 15}px;
  }
`;

const NavigationWrap = styled.div`
  text-align: right;

  .arrow {
    display: inline-block;
    cursor: pointer;
    opacity: 1;

    &:hover {
      opacity: 0.9;
    }

    &.swiper-button-disabled {
      opacity: 0.25;
    }

    &.back {
      margin-right: 5px;
    }
  }

  @media ${mediaQuery.md} {
    .arrow.back {
      margin-right: 20px;
    }
  }
`;

const LeftWrap = styled.div``;
const RightWrap = styled.div``;

const VideoP = styled(P)`
  font-size: 16px;
`;
