import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "shared/styles";
import { MenuLink } from "./Nav";
import { ArrowDown } from "./Icon";
import { useOutsideAlerter } from "shared/hooks/useOutsideAlerter";
import { useLocation } from "react-router-dom";

interface IDropdown {
  title: string;
  open: boolean;
  onToggle: () => void;
  onClose: () => void;
  titleStyle?: any;
  iconFill?: string;
}
export const Dropdown: FC<IDropdown> = ({
  title,
  open,
  onToggle,
  children,
  onClose,
  titleStyle = {},
  iconFill = colors.white,
}) => {
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location?.pathname]);

  const onOutside = () => {
    if (open) {
      onClose();
    }
  };
  useOutsideAlerter(dropdownRef, onOutside);

  return (
    <Wrap ref={dropdownRef} className="dropdown-menu">
      <MenuLink
        onClick={onToggle}
        style={{ ...titleStyle, paddingRight: 0 }}
        className={`dropdown-link ${open ? "dropdown-link-active" : ""}`}>
        {title}{" "}
        <ArrowDown
          className="dropdown-arrow"
          size={20}
          style={{ transition: "0.3s", transform: `rotate(${open ? 180 : 0}deg)` }}
          color={iconFill}
        />
      </MenuLink>
      <DropdownWrap className={open ? "active dropdown-body" : "inactive dropdown-body"}>{children}</DropdownWrap>
    </Wrap>
  );
};

const DropdownWrap = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 15px;
  padding: 8px 0;
  background: rgba(0, 0, 0, 0.9);
  width: 180px;
  opacity: 0;
  transition: 0.3s;
  max-height: 0;
  overflow: hidden;

  &.active {
    opacity: 1;
    max-height: 300px;
  }
`;

const Wrap = styled.div`
  position: relative;
`;
