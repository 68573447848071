import { useEffect, useRef, useState } from "react";
import createActivityDetector from "activity-detector";

const defaults = {
  timeToIdle: 10000,
  activityEvents: [
    "click",
    "mousemove",
    "keydown",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "focus",
  ],
  ignoredEventsWhenIdle: [],
};

export const useIsIdle = (options = {}) => {
  const [isIdle, setIsIdle] = useState(false);
  const activityRef = useRef(createActivityDetector({ ...defaults, ...options }));

  const stop = () => {
    if (!activityRef.current) return;

    activityRef?.current?.stop();
  };

  const restart = () => {
    activityRef?.current?.init();
  };

  useEffect(() => {
    activityRef.current.on("idle", () => setIsIdle(true));
    activityRef.current.on("active", () => setIsIdle(false));

    return stop;
  }, []);

  return { isIdle, restart };
};
