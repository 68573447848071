import { ReactComponent as Add } from "assets/icons/add.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow_down.svg";
import { ReactComponent as ArrowDownThin } from "assets/icons/arrow_down_thin.svg";
import { ReactComponent as ArrowForwardStem } from "assets/icons/arrow_forward_stem.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow_left.svg";
import { ReactComponent as ArrowLeftThin } from "assets/icons/arrow_left_thin.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow_right.svg";
import { ReactComponent as ArrowRightThin } from "assets/icons/arrow_right_thin.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow_up.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as Error } from "assets/icons/error.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as Pause } from "assets/icons/pause.svg";
import { ReactComponent as Play } from "assets/icons/play.svg";
import { ReactComponent as Remove } from "assets/icons/remove.svg";
import { ReactComponent as Success } from "assets/icons/success.svg";
import { ReactComponent as Volume } from "assets/icons/volume.svg";
import { ReactComponent as VolumeOff } from "assets/icons/volume_off.svg";
import { ReactComponent as Warning } from "assets/icons/warning.svg";

export const iconMap = {
  add: Add,
  arrow_down: ArrowDown,
  arrow_down_thin: ArrowDownThin,
  arrow_forward_stem: ArrowForwardStem,
  arrow_left: ArrowLeft,
  arrow_left_thin: ArrowLeftThin,
  arrow_right: ArrowRight,
  arrow_right_thin: ArrowRightThin,
  arrow_up: ArrowUp,
  close: Close,
  error: Error,
  info: Info,
  pause: Pause,
  play: Play,
  remove: Remove,
  success: Success,
  volume: Volume,
  volume_off: VolumeOff,
  warning: Warning,
};
