import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-grid-system";
import { MenuLink, Close as CloseIcon, Menu, MenuItem } from "shared/components";
import { colors, headerHeight, mediaQuery, zIndex } from "shared/styles";
import { CSSTransition } from "react-transition-group";
import "./index.scss";
import { Logo } from "modules/Header/components/Logo";
import { useRecoilState } from "recoil";
import { uiState } from "modules/UI";

export const Modal = ({ isOpen, close, children, hideHeader = false }) => {
  const [ui, setUiState] = useRecoilState(uiState);

  const onEnter = () => {
    setUiState((ui) => ({ ...ui, wrapperClass: "modal-open" }));
  };

  const onExited = () => {
    setUiState((ui) => ({ ...ui, wrapperClass: "" }));
  };

  return (
    <CSSTransition in={isOpen} timeout={300} classNames="modal" unmountOnExit onEnter={onEnter} onExited={onExited}>
      <Wrap>
        <Header>
          <Container style={{ width: "100%" }}>
            <Row align="center">
              <Col xs={4}>
                <Logo style={{ fill: colors.primary }} />
              </Col>
              <Col xs={8} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Menu>
                  <MenuItem>
                    <MenuLink onClick={close}>
                      Close <CloseIcon color={colors.white} style={{ marginLeft: 5 }} />
                    </MenuLink>
                  </MenuItem>
                </Menu>
              </Col>
            </Row>
          </Container>
        </Header>
        <Content className="content-wrap">
          <Container style={{ width: "100%" }}>
            <Row justify="center">
              <Col xs={12} sm={10}>
                {children}
              </Col>
            </Row>
          </Container>
        </Content>
      </Wrap>
    </CSSTransition>
  );
};

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight.sm}px;

  @media ${mediaQuery.md} {
    height: ${headerHeight.lg}px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${zIndex.modal};
`;
