import React, { FC, useState } from "react";
import { NotFound, OverlayRoute, P, H1 } from "shared/components";
import { useParams, RouteComponentProps } from "react-router-dom";
import { Routes } from "modules/Router/routes";
import { useGet } from "shared/hooks/useApi";
import { endpoints, IResourceResponse } from "modules/Api";
import { useToast } from "shared/components/Toast/hook";
import { Helmet } from "react-helmet";
import { ResourceHeader } from "modules/Dashboard/components/Resource/ResourceHeader";
import { ResourceTabs } from "modules/Dashboard/components/Resource/ResourceTabs";

export const Training: FC<RouteComponentProps> = ({ match, history }) => {
  const { id } = useParams<{ id: string }>();
  const [notFound, setNotFound] = useState(false);
  const toast = useToast({ type: "error", requiresDismiss: true });

  const { data, loading } = useGet<IResourceResponse>(`${endpoints.training}/${id}`, {}, (e) => {
    if (e?.response?.status === 404) {
      return setNotFound(true);
    }

    toast(e?.message || e);
  });

  const getBanner = () => {
    if (data?.summary?.banner) return data?.summary?.banner;
    if (data?.summary?.image) return data?.summary?.image;
    return "";
  };

  return (
    <OverlayRoute onBack={() => history.push(Routes.Dashboard)} match={match} type="training">
      {notFound && !loading ? (
        <NotFound>
          <Helmet>
            <title>Valor Connect - Not Found</title>
          </Helmet>
          <H1>Not Found</H1>
          <P variant="lg">{"The technical training you're looking for can't be found."}</P>
        </NotFound>
      ) : (
        <>
          <Helmet>
            <title>{`Valor Connect - ${data?.summary?.title || ""}`}</title>
          </Helmet>
          <ResourceHeader title={data?.summary?.title} desc={data?.summary?.desc} background={getBanner()} />
          <ResourceTabs loading={loading} data={data} />
        </>
      )}
    </OverlayRoute>
  );
};
