import React, { useState } from "react";
import { MenuLink, MenuItem, Dropdown, DropdownMenuLink } from "shared/components";
import { Routes } from "modules/Router/routes";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useIsAdmin } from "modules/User/hooks";
/**
 * Logged In Desktop Menu
 * 768px and up
 */
export const DesktopMenu = ({ openSubscribe, username, logout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <>
      {isAdmin ? (
        <>
          <DashboardLink className="dashboard-link">
            <NavLink to={Routes.Metrics} className="router-link">
              <MenuLink>View Dashboard</MenuLink>
            </NavLink>
          </DashboardLink>
          <SiteLink className="site-link">
            <NavLink to={Routes.Dashboard} className="router-link ">
              <MenuLink>View Site</MenuLink>
            </NavLink>
          </SiteLink>
        </>
      ) : (
        <>
          <MenuItem>
            <NavLink to={Routes.Feedback} className="router-link">
              <MenuLink>Feedback</MenuLink>
            </NavLink>
          </MenuItem>
          {/* <MenuItem>
            <MenuLink onClick={openSubscribe}>Subscribe</MenuLink>
          </MenuItem> */}
        </>
      )}

      <MenuItem>
        <Dropdown
          title={username}
          open={dropdownOpen}
          onToggle={() => setDropdownOpen((s) => !s)}
          onClose={() => setDropdownOpen(false)}>
          <DropdownMenuLink onClick={logout}>Logout</DropdownMenuLink>
        </Dropdown>
      </MenuItem>
    </>
  );
};

const SiteLink = styled(MenuItem)`
  display: none;

  .admin & {
    display: block;
  }
`;

const DashboardLink = styled(MenuItem)`
  .admin & {
    display: none;
  }
`;
