import React, { FC } from "react";
import styled from "styled-components";
import { Play, Pause } from "shared/components/Icon";

export const PlayBtn: FC<{ isPlaying: boolean; onClick?: () => void; size: number; width: number; style?: any }> = ({
  isPlaying,
  onClick = () => null,
  size = 18,
  width = 70,
  style = {},
}) => {
  const Icon = isPlaying ? Pause : Play;
  return (
    <PlayWrap onClick={onClick} style={{ ...style, width, height: width }} isPlaying={isPlaying}>
      <Icon color="#ffffff" size={size} />
    </PlayWrap>
  );
};

const PlayWrap = styled.span<{ isPlaying: boolean }>`
  background: rgba(0, 0, 0, 0.35);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: ${({ isPlaying }) => (isPlaying ? 0 : "1px")};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;
