import { atom } from "recoil";

export type ToastType = "default" | "warning" | "success" | "error";
interface IToast {
  message: string;
  isOpen: boolean;
  /**
   * Time in milliseconds till auto closes
   */
  autoClose: number;
  /**
   * Wont close till user dismisses
   */
  requiresDismiss: boolean;
  /**
   * Toast type
   */
  type: ToastType;
}

export const toastInitialState = {
  message: "",
  isOpen: false,
  autoClose: 5000,
  requiresDismiss: false,
  type: "default" as ToastType,
};

export const toastState = atom<IToast>({
  key: "toast",
  default: { ...toastInitialState },
});
