import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { colors } from "shared/styles";
import { ArrowLeft, ArrowRight } from "shared/components";

const DEFAULT_PAGES_SHOWING = 6;

export const Pagination = ({ currentPage, totalPages, onPageSelect }) => {
  const hasMorePages = totalPages > DEFAULT_PAGES_SHOWING;
  const pagesToShow = hasMorePages ? DEFAULT_PAGES_SHOWING : totalPages;
  return (
    <Wrap>
      <PrevNext
        onClick={() => onPageSelect(Math.max(currentPage - 1, 1))}
        className={currentPage < 2 ? "disabled" : ""}
        disabled={currentPage < 2}>
        <ArrowLeft color={colors.white} size={28} />
      </PrevNext>
      <Pages>
        {[...new Array(pagesToShow)].map((m, i) => {
          return (
            <Page key={i} onClick={() => onPageSelect(i + 1)} className={i + 1 === currentPage ? "active" : ""}>
              {i + 1}
            </Page>
          );
        })}
        {hasMorePages && <Page onClick={() => onPageSelect(Math.min(currentPage + 1, totalPages))}>...</Page>}
        {hasMorePages && <Page onClick={() => onPageSelect(totalPages)}>{totalPages}</Page>}
      </Pages>
      <PrevNext
        onClick={() => onPageSelect(Math.min(currentPage + 1, totalPages))}
        className={currentPage >= totalPages ? "disabled" : ""}
        disabled={currentPage >= totalPages}>
        <ArrowRight color={colors.white} size={28} />
      </PrevNext>
    </Wrap>
  );
};

const Wrap = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: inline-block;
    margin: 2px;
    padding: 8px 10px;
    border: none;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: ${colors.white};

    &:hover {
      color: ${colors.primary};

      svg {
        fill: ${colors.primary};
      }
    }
  }
`;

const PrevNext = styled.button`
  padding: 0;

  &.disabled {
    pointer: default;
    &:hover {
      svg {
        fill: rgba(255, 255, 255, 0.3);
      }
    }
    svg {
      fill: rgba(255, 255, 255, 0.3);
    }
  }
`;

const Page = styled.button`
  &.active {
    color: ${colors.primary};
  }
`;

const Pages = styled.div`
  margin: 0 5px;
  padding-bottom: 2px;
`;
