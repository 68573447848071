import React, { FC } from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import { Routes } from "modules/Router/routes";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "modules/Router/router.css";
import { Metrics } from "./Metrics";
import { Overlay } from "shared/components";
import styled from "styled-components";
import { AdminPage } from "modules/Admin/components";
import { Helmet } from "react-helmet";
import { useGet } from "shared/hooks";
import { endpoints, IDashboardResponse } from "modules/Api";
import { VimeoBRoll } from "modules/Landing";

export const Admin = () => {
  const { data, loading } = useGet<IDashboardResponse>(endpoints.dashboard);

  return (
    <div className="admin-zone">
      {!loading && (
        <VimeoBRoll videoId={data?.welcome?.intro?.bRoll} renderOverlay={() => <Overlay opacity={0.96} />} />
      )}
      <Wrap>
        <Helmet>
          <title>Valor Connect - Admin</title>
        </Helmet>
        <AdminRoutes />
      </Wrap>
    </div>
  );
};

const AdminRoutes = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition timeout={300} classNames="route" key={location.key}>
        <Switch location={location}>
          <Route
            exact
            path={`${Routes.Metrics}`}
            render={(props) => (
              <AdminPage>
                <Metrics {...props} />
              </AdminPage>
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Wrap = styled.div`
  position: relative;
  z-index: 10;
  height: 100%;
`;
