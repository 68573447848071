import styled from "styled-components";
import { getBaseTypoStyles } from "./Typography";

export const Ul = styled.ul`
  padding-left: 25px;
`;

export const Li = styled.li`
  ${(props) => getBaseTypoStyles(props)};
  margin: 7px 0;
`;
