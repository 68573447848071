import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { colors } from "shared/styles";
// loader builder -> https://skeletonreact.com/
/**
 * Header and paragraph text
 */
// export const FeatureTextLoader = (props) => (
//   <ContentLoader
//     speed={3}
//     width={400}
//     height={460}
//     viewBox="0 0 400 460"
//     backgroundColor={colors.grey4}
//     foregroundColor={colors.grey3}
//     {...props}>
//     <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
//     <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
//     <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
//     <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
//     <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
//     <circle cx="20" cy="20" r="20" />
//   </ContentLoader>
// );

export const InnerPageLoader = ({ children, ready }) => (
  <ReactPlaceholder
    type="text"
    ready={ready}
    rows={15}
    showLoadingAnimation={true}
    style={{ padding: "0 20px", maxWidth: 1200, margin: "0 auto" }}>
    {children}
  </ReactPlaceholder>
);

export const AdminOverviewLoader = ({ children, ready }) => (
  <ReactPlaceholder type="text" ready={ready} rows={6} showLoadingAnimation={true}>
    {children}
  </ReactPlaceholder>
);

export const AdminPerformingLoader = ({ children, ready }) => (
  <ReactPlaceholder type="text" ready={ready} rows={12} showLoadingAnimation={true}>
    {children}
  </ReactPlaceholder>
);

export const AdminDealersLoader = ({ children, ready }) => (
  <ReactPlaceholder type="textRow" ready={ready} rows={12} showLoadingAnimation={true}>
    {children}
  </ReactPlaceholder>
);
