import { useScreenClass } from "react-grid-system";

/**
 * Currently anything equal to `sm` media query and below
 */
export const useIsMobile = (includeMd = false): boolean => {
  const screenClass = useScreenClass();

  const sizes = includeMd ? ["xs", "sm", "md"] : ["xs", "sm"];

  return sizes.includes(screenClass);
};

export const useIsDesktop = (): boolean => {
  const screenClass = useScreenClass();

  return ["md", "lg", "xl", "xxl"].includes(screenClass);
};

export const useIsWidescreen = (): boolean => {
  const screenClass = useScreenClass();

  return ["xl", "xxl"].includes(screenClass);
};
