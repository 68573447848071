import React, { FC } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { mediaQuery } from "shared/styles";

export const Intro = ({ children }) => {
  return (
    <Container>
      <Row align="center" style={{ height: "100%" }} justify="start">
        <Col md={6} xl={6}>
          <ContentWrap>{children}</ContentWrap>
        </Col>
      </Row>
    </Container>
  );
};

const ContentWrap = styled.div`
  margin-top: -50px;
  @media ${mediaQuery.md} {
    margin-top: -120px;
  }
  @media ${mediaQuery.xxl} {
    padding-left: 100px;
  }
`;
