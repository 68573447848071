import React, { FC } from "react";
import styled from "styled-components";
import { H3, P, RadioPlus } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import { useRecoilState } from "recoil";
import { sessionState } from "modules/Auth";
import { updateUser } from "modules/Api/service";

const options = [
  { value: 1, label: "Very Negative" },
  { value: 2, label: "Negative" },
  { value: 3, label: "Neutral" },
  { value: 4, label: "Positive" },
  { value: 5, label: "Very Positive" },
];

export const Ratings = () => {
  const [sessState, setSessionState] = useRecoilState(sessionState);

  const onChange = async (feedback: number | string) => {
    const updatedSession = { ...sessState, user: { ...sessState.user, feedback: feedback.toString() } };
    setSessionState(updatedSession);

    // TODO handle error stuff
    await updateUser({
      data: {
        feedback,
      },
    });
  };

  const currentFeedback = sessState?.user?.feedback ? parseFloat(sessState?.user?.feedback) : 0;

  return (
    <Wrap>
      <RadioPlus
        label="How was your overall experience?"
        onChange={onChange}
        selected={currentFeedback}
        options={options}
      />
    </Wrap>
  );
};

const Label = styled(P)`
  font-size: 10px;

  @media ${mediaQuery.md} {
    font-size: 14px;
  }
`;

const Dot = styled(H3)`
  display: inline-block;
  margin: 0 auto 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: ${colors.grey1};
  transition: 0.3s;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: ${colors.primary};
  }

  @media ${mediaQuery.md} {
    font-size: 36px;
    width: 78px;
    height: 78px;
    line-height: 78px;
  }
`;

const Wrap = styled.div`
  margin: 30px 0;
`;

const RatingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media ${mediaQuery.sm} {
    justify-content: flex-start;
  }
`;

const Rating = styled.div`
  margin: 10px;
  cursor: pointer;
  text-align: center;

  &.active {
    h3 {
      color: #fff;
      background-color: ${colors.primary};
    }
  }

  @media ${mediaQuery.md} {
    margin: 20px;
  }

  @media ${mediaQuery.lg} {
    width: 150px;
    margin: 0;
    padding-top: 20px;
  }
  @media ${mediaQuery.xl} {
    width: 160px;
    margin: 0;
  }
`;
