import React, { useCallback, useEffect, useRef, useState } from "react";
import { Label, PageLoader } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import styled from "styled-components";
import { Col, Container, Row } from "react-grid-system";
import { useGet } from "shared/hooks/useApi";
import { endpoints } from "modules/Api";
import { TabContent } from "./Tabs/TabContent";
import { Routes } from "modules/Router/routes";
import { useRecoilState, useRecoilValue } from "recoil";
import { dashboardState } from "modules/Dashboard";
import { CSSTransition } from "react-transition-group";
import { uiState } from "modules/UI";
import { Overlay } from "shared/components";

const routes = [
  { key: 0, label: "Welcome" },
  { key: 1, label: "New Products" },
  { key: 2, label: "Product Upgrades" },
  { key: 3, label: "Technical Training" },
];

export interface IIntro {
  subTitle: string;
  title: string;
  desc: string;
}

export interface IItem {
  id: string;
  title: string;
  desc: string;
  image: string;
}

export interface ITabData {
  intro: IIntro;
  items?: IItem[];
}

const AUTOPLAY = 10 * 1000;
const INTERVAL = 50;

export const FeatureTabs = ({ onChange, data, loading }) => {
  const [dashboard, setDashboard] = useRecoilState(dashboardState);
  const [active, setActive] = useState<number>(0);
  const [progressWidth, setProgressWidth] = useState<string>("0%");
  const autoplayInterval = useRef<number | null>(null);
  const { isIdle } = useRecoilValue(uiState);

  useEffect(() => {
    return () => {
      destroyAutoplay();
    };
  }, []);

  const destroyAutoplay = () => {
    if (autoplayInterval.current) {
      clearInterval(autoplayInterval.current);
    }

    autoplayInterval.current = null;
    setProgressWidth("0%");
    setDashboard((d) => ({ ...d, interactedWithTabs: false }));
  };

  const onAutoplay = () => {
    let timer = 0;
    destroyAutoplay();

    autoplayInterval.current = setInterval(() => {
      timer = timer + INTERVAL;
      updateProgressWidth(timer);

      if (timer >= AUTOPLAY) {
        timer = 0;
        setActive((cur) => {
          if (cur === 3) return 0;
          return cur + 1;
        });
        onChange();
      }
    }, INTERVAL);
  };

  const onTabClick = (key) => {
    setActive(key);
    setDashboard((d) => ({ ...d, interactedWithTabs: true }));
  };

  useEffect(() => {
    if (!dashboard.dashboardLandingFocused) {
      destroyAutoplay();
      return;
    }

    if (!loading) {
      onAutoplay();
    }
  }, [loading, dashboard.dashboardLandingFocused]);

  useEffect(() => {
    if (isIdle && dashboard.dashboardLandingFocused && !autoplayInterval.current) {
      onAutoplay();
    }
  }, [isIdle, autoplayInterval.current, dashboard.dashboardLandingFocused]);

  useEffect(() => {
    if (dashboard.interactedWithTabs) {
      destroyAutoplay();
    }
  }, [dashboard.interactedWithTabs]);

  const updateProgressWidth = (time) => {
    setProgressWidth(`${(time / AUTOPLAY) * 100}%`);
  };

  return (
    <Wrap className="featured-tabs">
      <Gradient className={active !== 0 ? "darker" : "welcome"} />
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <ContentWrap>
            <CSSTransition in={active === 0} timeout={300} classNames="tab" unmountOnExit>
              <TabContent linkUrlBase={Routes.Products} data={data?.welcome} />
            </CSSTransition>
            <CSSTransition in={active === 1} timeout={300} classNames="tab" unmountOnExit>
              <TabContent linkUrlBase={Routes.Products} data={data?.products} />
            </CSSTransition>
            <CSSTransition in={active === 2} timeout={300} classNames="tab" unmountOnExit>
              <TabContent linkUrlBase={Routes.Upgrades} data={data?.upgrades} />
            </CSSTransition>
            <CSSTransition in={active === 3} timeout={300} classNames="tab" unmountOnExit>
              <TabContent linkUrlBase={Routes.Training} data={data?.training} />
            </CSSTransition>
          </ContentWrap>
          <TabBar>
            <Container>
              <Row>
                <Col>
                  <TabWrap>
                    {routes.map((r) => (
                      <Tab key={r.key} className={r.key === active ? "active" : ""} onClick={() => onTabClick(r.key)}>
                        <Border className={"border"} />
                        {r.key === active && autoplayInterval.current && (
                          <Progress className={"progress"} style={{ width: progressWidth }} />
                        )}
                        <TabLabel>{r.label}</TabLabel>
                      </Tab>
                    ))}
                  </TabWrap>
                </Col>
              </Row>
            </Container>
          </TabBar>
        </>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position: relative;

  // transition stuff for tabs
  .tab-enter {
    opacity: 0;
    transform: translateX(50px);
  }
  .tab-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: 0.3s;
  }
  .tab-exit {
    opacity: 1;
    display: none;
  }
  .tab-exit-active {
    opacity: 0;
  }
`;

const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  opacity: 1;
`;

const TabBar = styled.div`
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  flex-direction: row;
  z-index: 10;

  @media ${mediaQuery.md} {
    bottom: 20px;
  }
`;

const TabWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Tab = styled.div`
  padding-top: 5px;
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23%;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  &:hover {
    .border {
      background-color: ${colors.white};
    }

    label {
      color: ${colors.white};
    }
  }

  label {
    color: rgba(255, 255, 255, 0.4);
  }

  .progress {
    display: none;
  }

  &.active {
    cursor: default;
    .border {
      background-color: ${colors.white};
    }

    .progress {
      display: block;
    }

    label {
      cursor: default;
      color: ${colors.white};
    }
  }

  @media ${mediaQuery.md} {
    padding-top: 10px;
  }
`;

const Base = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 1px;
`;

const Border = styled(Base)`
  background-color: rgba(255, 255, 255, 0.12);
  width: 100%;
`;
const Progress = styled(Base)`
  background-color: ${colors.primary};
`;

const TabLabel = styled(Label)`
  cursor: pointer;
  text-align: center;

  @media ${mediaQuery.mdMax} {
    font-size: 10px;
  }
`;

const Gradient = styled(Overlay)`
  z-index: -1;
  transition: 0.2s;

  background: radial-gradient(23.09% 41.05% at 0% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(210.94deg, rgba(0, 0, 0, 0.6) 4.2%, rgba(0, 0, 0, 0) 41.43%),
    linear-gradient(360deg, rgba(0, 0, 0, 0.8) -0.62%, rgba(0, 0, 0, 0) 50.74%),
    radial-gradient(
      59.86% 106.42% at 18.61% 54.32%,
      rgba(0, 0, 0, 0.88) 0%,
      rgba(0, 0, 0, 0.577712) 54.71%,
      rgba(0, 0, 0, 0) 100%
    );

  &.darker {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      radial-gradient(23.09% 41.05% at 0% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(210.94deg, rgba(0, 0, 0, 0.6) 4.2%, rgba(0, 0, 0, 0) 41.43%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 42.84%),
      radial-gradient(
        59.86% 106.42% at 18.61% 54.32%,
        rgba(0, 0, 0, 0.704) 0%,
        rgba(0, 0, 0, 0.46217) 54.71%,
        rgba(0, 0, 0, 0) 100%
      );
  }
`;
