import axios, { AxiosRequestConfig } from "axios";
import { ISession } from "modules/Auth";
import { getStorage } from "modules/Storage";
import { IAPIResponse } from "./typings";

const axClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

axClient.interceptors.request.use((config) => {
  const session: ISession | null = getStorage("session");
  config.params = config.params || {};
  if (session?.token) {
    config.params["token"] = session.token;
  }
  return config;
});

// export const getBaseUrl = () => {
//   return `${process.env.REACT_APP_API_URL}`;
// };

export const post = (
  endpoint: string,
  data: any = {},
  config: AxiosRequestConfig = {},
  retries = 0,
): Promise<IAPIResponse> => {
  const url = `${endpoint}`;
  let attempts = 0;

  // TODO make reuseuable for all api calls
  const retryFetch = async (url: string) => {
    try {
      return await axClient.post(url, data, config);
    } catch (e) {
      if (attempts < retries) {
        attempts++;
        return retryFetch(url);
      }

      throw e;
    }
  };

  return retryFetch(url);
};

export const put = (
  endpoint: string,
  data: any = {},
  config: AxiosRequestConfig = {},
  retries = 0,
): Promise<IAPIResponse> => {
  const url = `${endpoint}`;
  let attempts = 0;

  // TODO make reuseuable for all api calls
  const retryFetch = async (url: string) => {
    try {
      return await axClient.put(url, data, config);
    } catch (e) {
      if (attempts < retries) {
        attempts++;
        return retryFetch(url);
      }

      throw e;
    }
  };

  return retryFetch(url);
};

export const get = (
  endpoint: string,
  params = {},
  config: AxiosRequestConfig = {},
  retries = 0,
): Promise<IAPIResponse> => {
  const url = `${endpoint}`;
  let attempts = 0;

  // TODO make reuseuable for all api calls
  const retryFetch = async (url: string) => {
    try {
      return await axClient.get(url, { ...config, params: { ...params } });
    } catch (e) {
      if (attempts < retries) {
        attempts++;
        return retryFetch(url);
      }

      throw e;
    }
  };

  return retryFetch(url);
};
