import { uiState } from "modules/UI";
import React, { FC, useEffect, useState } from "react";
import { InnerPage, H1, OverlayRoute, P, InnerBodyMax } from "shared/components";
import { Routes } from "modules/Router/routes";
import { Col, Container, Row } from "react-grid-system";
import { mediaQuery } from "shared/styles";
import { Ratings } from "modules/Dashboard";

export const ThankYou = ({ match, history }) => {
  const [feedback, setFeedback] = useState<number>(0);
  return (
    <OverlayRoute onBack={() => history.push(Routes.Dashboard)} match={match} type="inner-page">
      <InnerPage className="inner-body">
        <InnerBodyMax>
          <Row>
            <Col md={8} lg={6}>
              <H1>Thank You</H1>
              <P variant="lg">
                Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit
                amet risus. Cras justo odio, dapibus ac facilisis in, egestas eget quam.{" "}
              </P>
            </Col>
          </Row>
          <Row>
            <Col>
              <Ratings />
            </Col>
          </Row>
        </InnerBodyMax>
      </InnerPage>
    </OverlayRoute>
  );
};
