import React, { FC, useEffect } from "react";
import { Switch, Route, BrowserRouter, Redirect, Link, useLocation } from "react-router-dom";
import { Admin, Landing } from "pages";
import { Header } from "modules/Header/components";
import { Dashboard } from "pages/authed/Dashboard";
import { Routes } from "./routes";
import { useAdminRoute, useIsAdmin, useIsLoggedIn } from "modules/User/hooks";
import { useSetRecoilState } from "recoil";
import { uiState } from "modules/UI";

const LoginRoute = `${Routes.Landing}?login=1`;

/**
 * User has to be authed (dealer/admin)
 */
const AuthedRoute = ({ children, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LoginRoute,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

/**
 * User has to be admin
 * TODO - lets run auth check endpoint when you hit the admin area
 */
const AdminRoute = ({ children, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();

  const { loading, isAdmin } = useAdminRoute();

  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isLoggedIn ? Routes.Dashboard : LoginRoute,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const GuestRoute = ({ children, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.Dashboard,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

/**
 * Could do 404 page but lets just send user to their
 * landing page
 */
const NoMatch = () => {
  const isLoggedIn = useIsLoggedIn();
  const redirect = isLoggedIn ? Routes.Dashboard : LoginRoute;

  return <Redirect to={redirect} />;
};

export const Router = () => {
  return (
    <BrowserRouter>
      <RouteChange>
        <Header />

        <Switch>
          <Route exact path={Routes.Landing}>
            <GuestRoute>
              <Landing />
            </GuestRoute>
          </Route>
          <Route path={Routes.Dashboard}>
            <AuthedRoute>
              <Dashboard />
            </AuthedRoute>
          </Route>
          <Route path={Routes.Admin}>
            <AdminRoute>
              <Admin />
            </AdminRoute>
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </RouteChange>
    </BrowserRouter>
  );
};

const RouteChange = ({ children }) => {
  const location = useLocation();
  const setUiState = useSetRecoilState(uiState);

  useEffect(() => {
    setUiState((s) => ({ ...s, wrapperClass: location?.pathname }));
  }, [location?.pathname]);

  return <>{children}</>;
};
