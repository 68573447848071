import React, { FC, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { uiState } from "../state";
import { useIsIdle } from "shared/hooks";

export const Idle = ({ children, onIdleChange }) => {
  const { isIdle, restart } = useIsIdle({ timeToIdle: 10 * 1000 });

  const windowFocus = () => {
    onIdleChange(false);
    restart();
  };

  const windowBlur = () => {
    // console.log("blur");
  };

  useEffect(() => {
    window.addEventListener("focus", windowFocus);
    window.addEventListener("blur", windowBlur);
    return () => {
      window.removeEventListener("focus", windowFocus);
      window.removeEventListener("blur", windowBlur);
    };
  }, []);

  useEffect(() => {
    onIdleChange(isIdle);
  }, [isIdle]);

  return children;
};
