import React, { FC, memo } from "react";
import { useSetRecoilState } from "recoil";
import { ITabData } from "../FeatureTabs";
import { TabSlider } from "./TabSlider";
import { dashboardState } from "modules/Dashboard";

interface ITabContent {
  data?: ITabData;
  linkUrlBase?: string;
}
export const TabContent: FC<ITabContent> = memo(({ data = {}, linkUrlBase }) => {
  const cards = (data?.items || []).map((i) => ({ ...i, url: `${linkUrlBase}/${i.id}` }));
  const setDashboard = useSetRecoilState(dashboardState);

  const onInteraction = () => {
    setDashboard((d) => ({ ...d, interactedWithTabs: true, tooltips: { ...d.tooltips, sliderScroll: true } }));
  };

  return <TabSlider intro={data?.intro} cards={cards} onInteraction={onInteraction} />;
});
