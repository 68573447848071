import { isAdminSelector, isLoggedInSelector } from "modules/Auth";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { autoLogin } from "modules/Api/service";
import { IRole } from ".";

export const useIsLoggedIn = (): boolean => {
  const isLoggedIn = useRecoilValue(isLoggedInSelector);

  return isLoggedIn;
};

export const useIsAdmin = (): boolean => {
  const isAdmin = useRecoilValue(isAdminSelector);

  return isAdmin;
};

export const useAdminRoute = (): {
  loading: boolean;
  isAdmin: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await autoLogin();
        const isAdmin = data?.role === IRole.Admin;
        setIsAdmin(isAdmin);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    fetch();
  }, []);

  return {
    loading,
    isAdmin,
  };
};
