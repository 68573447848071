import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../Buttons";
import { CSSTransition } from "react-transition-group";
import { Col, Container, Row } from "react-grid-system";
import { mediaQuery } from "shared/styles";

export interface ITab {
  key: string;
  label: string;
  content: () => React.ComponentElement<any, any>;
  disableContentContainer?: boolean;
}

interface ITabProps {
  tabs: ITab[];
  defaultTab?: string;
}

export const Tabs: FC<ITabProps> = (props) => {
  const { tabs, defaultTab } = props;
  const [active, setActive] = useState<string>(defaultTab || "");

  const onClick = (key) => {
    setActive(key);
  };

  const renderTabs = () => {
    return tabs.map((r) => (
      <Tab
        onClick={() => onClick(r.key)}
        className={r.key === active ? "active" : ""}
        key={r.key}
        variant="text"
        size="md">
        {r.label}
      </Tab>
    ));
  };

  if (!tabs.filter((r) => r.key && r.label && r.content).length) return null;

  const renderContent = (t) => {
    if (t?.disableContentContainer) return t.content();

    return (
      <Container>
        <Row>
          <Col xs={12}>{t.content()}</Col>
        </Row>
      </Container>
    );
  };

  return (
    <Wrap>
      <Container>
        <Row>
          <Col xs={12}>
            <TabsWrap>{renderTabs()}</TabsWrap>
          </Col>
        </Row>
      </Container>
      {tabs.map((t) => (
        <CSSTransition key={t.key} in={active === t.key} timeout={300} classNames="generic-tab" unmountOnExit>
          <TabContent className="tab-content">{renderContent(t)}</TabContent>
        </CSSTransition>
      ))}
    </Wrap>
  );
};

const Wrap = styled.div`
  .generic-tab-enter {
    opacity: 0;
  }
  .generic-tab-enter-active {
    opacity: 1;
    transition: 0.3s;
  }
  .generic-tab-exit,
  .generic-tab-exit-active {
    display: none;
  }
`;

const TabContent = styled.div`
  padding: 20px 0;
`;

const Tab = styled(Button)`
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 7px;
  font-size: 13px;

  &:first-child {
    margin-left: 0;
  }

  @media ${mediaQuery.md} {
    padding: 10px 16px;
    font-size: 14px;
  }
`;

const TabsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
