import React, { FC } from "react";
import styled from "styled-components";
import { ReactComponent as LogoSVG } from "assets/imgs/logo.svg";
import { mediaQuery } from "shared/styles";

export const Logo = (props) => <StyledLogo {...props} />;

const StyledLogo = styled(LogoSVG)`
  width: 60px;
  display: inline-block;
  transition: 0.3s;
  cursor: pointer;
  margin-top: 5px;

  @media ${mediaQuery.md} {
    width: 85px;
    margin-top: 0;
  }
`;
