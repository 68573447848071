import React, { FC } from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { H1, P } from "shared/components";
import { mediaQuery } from "shared/styles";
import { useIsMobile } from "shared/hooks";

interface IHeader {
  title?: string;
  desc?: string;
  background?: string;
}
export const ResourceHeader: FC<IHeader> = ({ title = "", desc = "", background = "" }) => {
  const isMobile = useIsMobile();

  return (
    <Wrap className="product-header">
      <Content>
        <Container style={{ width: "100%" }}>
          <Row align={isMobile ? "start" : "end"}>
            <Col xs={12} md={6} xl={5} xxl={4}>
              <TitleWrap>
                <H1 color="white" mb={!isMobile ? 0 : undefined}>
                  {title}
                </H1>
              </TitleWrap>
            </Col>
            <Col xs={12} md={6} xl={6} offset={{ xl: 1, xxl: 2 }}>
              <DescWrap>
                <P color="white" mb={0} variant={isMobile ? "normal" : "lg"}>
                  {desc}
                </P>
              </DescWrap>
            </Col>
          </Row>
        </Container>
      </Content>
      <BgImage style={{ backgroundImage: `url(${background})` }} />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
  background-color: black;
  overflow: hidden;
`;

const BgImage = styled.div`
  background-size: cover;
  background-color: black;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(10px);
`;

const Content = styled.div`
  padding: 70px 0 20px;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  background: rgba(0, 0, 0, 0.25);

  @media ${mediaQuery.md} {
    padding: 30px 0;
    height: 320px;
    align-items: flex-end;
  }

  @media ${mediaQuery.lg} {
    padding: 60px 0;
  }
`;

const TitleWrap = styled.div``;
const DescWrap = styled.div``;
// const HeaderP = styled(P)`

// `;
