import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "react-grid-system";
import { H3, DarkInput, Table, Tr, Th, Td, Loader, Close, P } from "shared/components";
import { colors, mediaQuery } from "shared/styles";
import { DealerRow } from "./DealerRow";
import { useIsMobile } from "shared/hooks";
import { endpoints, get, IDealer } from "modules/Api";
import throttle from "lodash/throttle";
import { Pagination } from "./Pagination";

const debouncedGet = throttle(async (endpoint, params) => {
  return await get(endpoint, params);
}, 300);

const useDealers = (query, page) => {
  const [data, setData] = useState({ dealers: [], currentPage: 1, totalPages: 1 });
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await debouncedGet(endpoints.dealers, {
          query,
          page,
        });
        setData(data);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    };

    fetch();

    return () => {
      setLoading(false);
      setError("");
    };
  }, [query, page]);

  return {
    data,
    error,
    loading,
  };
};

interface IDealersData {
  dealers: IDealer[];
  currentPage: number;
  totalPages: number;
}

export const Dealers = () => {
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const isMobile = useIsMobile();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const { data, loading }: { data: IDealersData; loading: boolean; error: string } = useDealers(searchTerm, page);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const resetSearch = () => {
    setSearchTerm("");
    setPage(1);
  };

  return (
    <DealerWrap>
      <DealerTitle>
        <Row justify="between">
          <Col>
            <H3 mb={0}>Dealers</H3>
          </Col>
          <Col xs="content">
            <SearchWrap>
              {searchTerm && (
                <SearchReset onClick={resetSearch}>
                  <Close size={20} color={colors.white} />
                </SearchReset>
              )}
              <SearchInput type="text" placeholder="Search Dealer" onChange={onSearch} value={searchTerm} />
            </SearchWrap>
          </Col>
        </Row>
      </DealerTitle>
      <Wrap>
        <Table className="light row-highlight">
          <tbody>
            <Tr>
              <Th>Name</Th>
              <Th align="center" hidden={isMobile}>
                Product Videos
              </Th>
              <Th align="center" hidden={isMobile}>
                Training Videos
              </Th>
              <Th align="center" hidden={isMobile}>
                Resources
              </Th>
              <Th align="center" hidden={isMobile}>
                Polls
              </Th>
              <Th width={50}></Th>
            </Tr>
            {loading ? (
              <Tr>
                <Th colSpan={6} style={{ borderBottom: "none" }}>
                  <LoaderWrap>
                    <Loader size={60} />
                  </LoaderWrap>
                </Th>
              </Tr>
            ) : (
              <>
                {data?.dealers ? (
                  (data.dealers || []).map((d, i) => (
                    <DealerRow
                      dealer={d}
                      index={i}
                      key={`${d.id}-${i}`}
                      onToggle={() => {
                        setActiveRow((a) => (a === i ? null : i));
                      }}
                      active={activeRow}
                    />
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={6} style={{ borderBottom: "none", backgroundColor: "transparent" }}>
                      <P align={"center"}>
                        No dealers found.{" "}
                        <ResetLInk onClick={resetSearch} color={colors.primary}>
                          Reset your search
                        </ResetLInk>
                      </P>
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </tbody>
        </Table>
        {!loading && data?.dealers && data?.totalPages > 1 && (
          <Pagination currentPage={data?.currentPage} totalPages={data?.totalPages} onPageSelect={setPage} />
        )}
      </Wrap>
    </DealerWrap>
  );
};

const ResetLInk = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const SearchWrap = styled.div`
  position: relative;
`;

const SearchReset = styled.div`
  position: absolute;
  top: 7px;
  right: 5px;
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`;

const SearchInput = styled(DarkInput)`
  width: 150px;

  @media ${mediaQuery.lg} {
    width: 235px;
  }
`;

const DealerWrap = styled.div`
  margin-bottom: 40px;
  @media ${mediaQuery.md} {
    padding-left: 20px;
  }
  @media ${mediaQuery.xxl} {
    padding-left: 50px;
  }
`;

const DealerTitle = styled.div`
  margin-bottom: 30px;
`;

const Wrap = styled.div`
  .extra-info {
    display: none;
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      td {
        cursor: default !important;
        background-color: transparent !important;
      }
    }

    &.active {
      display: table-row;
    }
  }

  .dealer-row {
    &.active {
      background-color: rgba(255, 255, 255, 0.1);
      td {
        border: none;
      }
    }
  }
`;

const LoaderWrap = styled.div`
  margin: 50px 0;
`;
