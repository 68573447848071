import { IDealer } from "modules/Api";
import React, { FC } from "react";
import { P, ArrowDownThin, Tr, Td } from "shared/components";
import { useIsMobile } from "shared/hooks";
import { mediaQuery } from "shared/styles";
import styled from "styled-components";

interface IDealerRow {
  dealer: IDealer;
  onToggle: () => void;
  active: number | null;
  index: number;
}
export const DealerRow: FC<IDealerRow> = (props) => {
  const { dealer, onToggle, active, index } = props;
  const isMobile = useIsMobile();
  return (
    <>
      <Tr className={active === index ? "active dealer-row" : "dealer-row"} onClick={onToggle}>
        <Td>{dealer?.name || ""}</Td>
        <Td align="center" hidden={isMobile}>
          {dealer?.stats?.product || 0}
        </Td>
        <Td align="center" hidden={isMobile}>
          {dealer?.stats?.training || 0}
        </Td>
        <Td align="center" hidden={isMobile}>
          {dealer?.stats?.resource || 0}
        </Td>
        <Td align="center" hidden={isMobile}>
          {dealer?.stats?.polls || 0}
        </Td>
        <Td>
          <Down>
            <ArrowDownThin className="dropdown-arrow" size={18} color={"white"} />
          </Down>
        </Td>
      </Tr>
      <Tr className={active === index ? "active extra-info" : "extra-info"}>
        <Td colSpan={6}>
          <ExtraInfo>
            <P>
              <strong>Email: </strong> {dealer?.email}
            </P>
            {isMobile && (
              <MobileExtra>
                <P>
                  <strong>Product Videos: </strong>
                  {dealer?.stats?.product || 0}
                </P>
                <P>
                  <strong>Traning Videos: </strong>
                  {dealer?.stats?.training || 0}
                </P>
                <P>
                  <strong>Resources: </strong>
                  {dealer?.stats?.resource || 0}
                </P>
                <P>
                  <strong>Polls: </strong>
                  {dealer?.stats?.polls || 0}
                </P>
              </MobileExtra>
            )}
            <P>{dealer?.desc || ""}</P>
            <div>
              <P mb={0}>
                <strong>Detailed Stats</strong>
              </P>
              <Details>
                {(dealer?.stats?.detailed || [])
                  .filter((d) => d?.title && d?.count > -1)
                  .map((d, i) => (
                    <DetailedStat key={i}>
                      <P variant="sm" mb={0}>
                        {d?.title}: {d.count}
                      </P>
                    </DetailedStat>
                  ))}
              </Details>
            </div>
          </ExtraInfo>
        </Td>
      </Tr>
    </>
  );
};

const Details = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const DetailedStat = styled.div`
  width: 50%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 0;
  margin-bottom: 5px;

  @media ${mediaQuery.lg} {
    width: 25%;
  }
  @media ${mediaQuery.xl} {
    width: 20%;
  }
`;

const MobileExtra = styled.div`
  margin-bottom: 20px;
`;

const ExtraInfo = styled.div`
  padding: 10px;
`;

const Down = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s;
  transform-origin: center center;

  .active & {
    transform: rotate(180deg);
  }
`;
