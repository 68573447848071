import React from "react";
import { Router } from "modules/Router";
import { ScreenClassProvider } from "react-grid-system";
import { RecoilRoot } from "recoil";
import { Wrap } from "modules/UI/components/Wrap";
import { Boot } from "modules/Auth";
import { Toast } from "shared/components";
import { ErrorBoundary } from "modules/Logging/components/ErrorBoundary";

function App() {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <Wrap>
          <Boot>
            <ScreenClassProvider>
              <Router />
            </ScreenClassProvider>
            <Toast />
          </Boot>
        </Wrap>
      </ErrorBoundary>
    </RecoilRoot>
  );
}

export default App;
