import React, { FC } from "react";
import { ICard, Card } from "shared/components";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

interface ICardProps {
  cards: ICard[];
  onChange: (active: number) => void;
  intro?: () => React.ReactElement<any, any>;
}

export const MobileCardSlider: FC<ICardProps> = ({ cards, intro = () => null, onChange }) => {
  return (
    <Wrap className="card-slider card-slider-mobile">
      <Swiper spaceBetween={20} slidesPerView={"auto"} onSlideChange={({ activeIndex }) => onChange(activeIndex)}>
        <SwiperSlide className="intro-slide">{intro()}</SwiperSlide>
        {cards?.length && (
          <>
            {cards.map((card, i) => (
              <SwiperSlide key={i}>
                <CardWrap>
                  <Card {...card} />
                </CardWrap>
              </SwiperSlide>
            ))}
            <SwiperSlide className="end-slide"></SwiperSlide>
          </>
        )}
      </Swiper>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  .swiper-slide {
    width: 250px;
    display: block;
  }

  .intro-slide {
    align-self: center;
    width: 90%;
    max-width: 350px;
  }

  .end-slide {
    width: 20px;
  }
`;

const CardWrap = styled.div``;
